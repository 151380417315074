var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "product-image",
      style: {
        minWidth: _vm.computedWidth,
        minHeight: _vm.computedHeight,
      },
    },
    [
      _vm.product.image_url
        ? _c("ws-image", {
            attrs: { value: _vm.product, width: _vm.width, height: _vm.height },
          })
        : _c("font-awesome-icon", {
            style: {
              color: "red",
              width: _vm.computedIconWidth,
              height: _vm.computedIconHeight,
            },
            attrs: { icon: _vm.$missingProductIcon },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }