<template>
  <div class="ws-product-card" v-bind="$attrs" v-on="$listeners">
    <div class="product-details">
      <div class="name-and-description">
        <div class="name">{{ product.name }}</div>
        <div class="description">{{ product.web_description }}</div>
      </div>

      <div class="price">
        {{ priceStr }}
        <font-awesome-icon
          v-if="isInBag"
          icon="cart-shopping"
          :style="{ color: '#ccc', marginLeft: '0.5rem' }"
        />
      </div>
    </div>
    <ws-product-image :product="product" :width="100" :height="100" />
  </div>
</template>

<script>
export default {
  props: {
    menuProduct: {
      required: true,
      type: Object,
    },
    isInBag: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  computed: {
    product() {
      return this.menuProduct.product;
    },
    priceStr() {
      const minPriceStr = this.$formatCurrency(
        this.menuProduct.min_menu_product_line_price
      );

      if (this.menuProduct.menu_product_lines_count === 1) {
        return minPriceStr;
      }

      return `From ${minPriceStr}`;
    },
  },
  watch: {},
  async created() {},
};
</script>

<style lang="scss" scoped>
.ws-product-card {
  border: 1px solid var(--light-grey);
  border-radius: 5px;
  display: flex;
  gap: 1em;
  justify-content: space-between;
  width: 100%;
  padding: 15px 20px;
  align-items: center;
  background-color: white;
  cursor: pointer;

  .product-details {
    display: flex;
    justify-content: space-between;
    height: 100%;
    flex-direction: column;

    .name {
      font-weight: 500;
      margin-bottom: 0.5em;
    }

    .description {
      line-height: 1.6em;
      color: var(--text-muted);
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }

    .price {
      margin-top: 0.5em;
    }
  }

  transition: all 0.5s;
  .image {
    transition: all 0.5s;
    opacity: 1;
  }

  &:hover {
    // Sadly, transitioning linear gradients is not well supported.
    background: var(--very-transparent-grey);

    .image {
      opacity: 0.8;
    }
  }
}
</style>
