var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cart" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("div", { staticClass: "header" }, [
          _c("h1", [_vm._v(_vm._s(_vm.title))]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "continue-ordering" },
          [
            _c(
              "ws-button",
              { attrs: { href: "/", borderless: "" } },
              [
                _c("font-awesome-icon", { attrs: { icon: "caret-left" } }),
                _vm._v(" Continue Ordering\n      "),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.isSelectingFulfilmentSlot
          ? _c("ws-fulfilment-slots-modal", {
              attrs: { "fulfilment-slots": _vm.fulfilmentSlots },
              on: {
                "selected-fulfilment-slot": _vm.setSelectedFulfilmentSlot,
                close: function ($event) {
                  _vm.isSelectingFulfilmentSlot = false
                },
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "body" }, [
          _c("div", { staticClass: "cart-section" }, [
            _vm._m(0),
            _vm._v(" "),
            _vm.errors.length > 0
              ? _c(
                  "div",
                  { staticClass: "cart-errors" },
                  _vm._l(_vm.errors, function (error) {
                    return _c("div", { key: error }, [_vm._v(_vm._s(error))])
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.cartErrors.length > 0
              ? _c(
                  "div",
                  { staticClass: "cart-errors" },
                  _vm._l(_vm.cartErrors, function (error) {
                    return _c("div", { key: error }, [_vm._v(_vm._s(error))])
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "cart-subtitle" },
              [
                _vm.localBag.isEmpty()
                  ? [_vm._v("Your cart is empty")]
                  : [
                      _vm._v(
                        "YOUR ORDER (" +
                          _vm._s(
                            _vm.$pluralize(
                              "ITEM",
                              _vm.menuProductLinesInBag.length,
                              true
                            )
                          ) +
                          ")"
                      ),
                    ],
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "cart-items" },
              _vm._l(
                _vm.menuProductLinesInBag,
                function (menuProductLineInBag) {
                  return _c(
                    "div",
                    { key: menuProductLineInBag.id, staticClass: "cart-item" },
                    [
                      _c("ws-product-image", {
                        staticClass: "product-image",
                        attrs: {
                          product: menuProductLineInBag.product,
                          width: 80,
                          height: 80,
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "cart-description" },
                        [
                          _c("div", { staticClass: "cart-item-name" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(menuProductLineInBag.product.name) +
                                "\n              "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "cart-item-product-measure" },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    menuProductLineInBag.menu_product_line
                                      .product_line.product_measure.web_label
                                  ) +
                                  "\n              "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm._l(
                            _vm.$reject(menuProductLineInBag.options, {
                              is_declined: true,
                            }),
                            function (option) {
                              return _c(
                                "div",
                                {
                                  key: option.product_line_id,
                                  staticClass: "cart-item-option",
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "\n                  With\n                  " +
                                        _vm._s(
                                          _vm.productLinesById[
                                            option.product_line_id
                                          ].product.name
                                        ) +
                                        "\n                "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _vm.productLinesById[option.product_line_id]
                                    .price > 0
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$formatCurrency(
                                              _vm.productLinesById[
                                                option.product_line_id
                                              ].price
                                            )
                                          )
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            }
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "cart-item-quantity" },
                            [
                              _c("ws-quantity-buttons", {
                                on: { input: _vm.saveBag },
                                model: {
                                  value: menuProductLineInBag.quantity,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      menuProductLineInBag,
                                      "quantity",
                                      $$v
                                    )
                                  },
                                  expression: "menuProductLineInBag.quantity",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "cart-actions" }, [
                        _c("div", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$formatCurrency(
                                  _vm.getBagItemPrice(menuProductLineInBag)
                                )
                              ) +
                              "\n              "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c(
                              "ws-button",
                              {
                                attrs: { borderless: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.removeFromBag(
                                      menuProductLineInBag
                                    )
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: "trash", size: "lg" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  )
                }
              ),
              0
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "fulfilment-section" }, [
            _c("div", [
              _vm._m(1),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "fulfilment-time" },
                [
                  _c(
                    "ws-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.isSelectingFulfilmentSlot = true
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", { attrs: { icon: "clock" } }),
                      _vm._v("\n               \n              "),
                      _vm.selectedFulfilmentSlot
                        ? [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.$formatFulfilmentSlotTime(
                                    _vm.selectedFulfilmentSlot
                                  )
                                ) +
                                "\n              "
                            ),
                          ]
                        : [_vm._v("Choose a collection time")],
                    ],
                    2
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "totals" }, [
              _c("div", [_vm._v("Total")]),
              _vm._v(" "),
              _c("div", [_vm._v(_vm._s(_vm.$formatCurrency(_vm.total)))]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "checkout" },
              [
                _c(
                  "ws-button",
                  {
                    ref: "checkoutButton",
                    attrs: {
                      href: "/checkout",
                      variant: "cta-inverted",
                      block: "",
                      size: "lg",
                      disabled: _vm.total === 0,
                    },
                    on: {
                      click: function (event) {
                        return _vm.checkout(event)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n            Checkout " +
                        _vm._s(_vm.$formatCurrency(_vm.total)) +
                        "\n          "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c("footer", { staticClass: "footer" }, [
      _c("div", { staticClass: "secure-checkout-text" }, [
        _vm._v("\n      Secure checkout by\n      "),
        _c(
          "span",
          { staticClass: "fa-icon" },
          [
            _c("font-awesome-icon", {
              attrs: { icon: ["fab", "stripe"], size: "2x" },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "cc-icons" },
        [
          _c("font-awesome-icon", {
            attrs: { icon: ["fab", "cc-visa"], size: "lg" },
          }),
          _vm._v(" "),
          _c("font-awesome-icon", {
            attrs: { icon: ["fab", "cc-mastercard"], size: "lg" },
          }),
          _vm._v(" "),
          _c("font-awesome-icon", {
            attrs: { icon: ["fab", "cc-apple-pay"], size: "lg" },
          }),
          _vm._v(" "),
          _c("font-awesome-icon", {
            attrs: { icon: ["fab", "cc-amex"], size: "lg" },
          }),
          _vm._v(" "),
          _c("font-awesome-icon", {
            attrs: { icon: ["fab", "google-pay"], size: "lg" },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cart-title" }, [
      _c("h2", [_vm._v("YOUR CART")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "fulfilment-method" }, [
      _c("h3", [_vm._v("COLLECTION")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }