<template>
  <div class="gdbeer-index">
    <div
      v-if="placedOrder !== null"
      class="box gradient-border checkout-success"
      ref="placedOrder"
    >
      <div class="header">THANK YOU!</div>
      <div class="body">
        <div>We&apos;ve got your order.</div>
        <div class="collection-time">
          Please collect
          {{
            $formatFulfilmentSlotTime(placedOrder.fulfilmentSlot).toLowerCase()
          }}
        </div>
      </div>
    </div>

    <div class="d-flex flex-column align-items-center">
      <h1>Green Dragon</h1>
      <h2>Beer Takeaway Service</h2>
    </div>

    <div class="box instructions">
      <ol>
        <li>Choose your beer</li>
        <li>Provide your name &amp; number</li>
        <li>Choose a collection time</li>
        <li>Pay now or when you collect</li>
      </ol>
    </div>

    <div class="products">
      <div><h2>Order</h2></div>
      <div class="product-cards">
        <gdbeer-product-card
          v-for="menuProductLine in menuProductLines"
          :width="200"
          :key="menuProductLine.id"
          :title="menuProductLine.product.name"
          :price="menuProductLine.price"
          :abv="menuProductLine.product.abv"
          :image-url="menuProductLine.product.image_url"
          :info="menuProductLine.product.web_description"
          :product-measure-web-label="
            menuProductLine.product_line.product_measure.web_label
          "
          v-model="menuProductLine.quantity"
        />
      </div>
    </div>

    <div v-if="menuProductLinesInBag.length === 0" class="box">
      Your basket is empty.
    </div>

    <div class="basket-and-order-form d-flex gap-5 flex-column" v-else>
      <div class="box basket">
        <div
          class="basket-item"
          v-for="menuProductLine in menuProductLinesInBag"
          :key="menuProductLine.id"
        >
          <div>
            {{ menuProductLine.product.name }}
            <span class="text-muted">{{
              menuProductLine.product_line.product_measure.web_label
            }}</span>
          </div>
          <div>
            {{ menuProductLine.quantity }}
            <span :style="{ fontWeight: 400 }">&times;</span>
          </div>
          <div>
            {{
              $formatCurrency(menuProductLine.price * menuProductLine.quantity)
            }}
          </div>
        </div>
        <hr />
        <hr />
        <hr />

        <div class="basket-item">
          <div>Total</div>
          <div />
          <div>
            {{
              $formatCurrency(
                $sumBy(
                  menuProductLinesInBag,
                  (menuProductLine) =>
                    menuProductLine.price * menuProductLine.quantity
                )
              )
            }}
          </div>
        </div>
      </div>

      <div class="order-form">
        <h2>Your details</h2>
        <b-form-group
          id="customer-name"
          description="Enter a name so we know who you are when you collect."
          label="Enter your name"
          valid-for="customer-name-input"
          :invalid-feedback="formErrors.customer_name"
          :state="formStates.customer_name"
        >
          <b-input
            id="customer-name-input"
            v-model="formData.customer_name"
            :state="formStates.customer_name"
            autocomplete="name"
            @input="formErrors.customer_name = null"
            trim
          />
        </b-form-group>

        <b-form-group
          id="customer-phone-number"
          description="Enter a phone number so we can contact you when your order is ready."
          label="Enter your phone number"
          valid-for="customer-phone-number-input"
          :invalid-feedback="formErrors.customer_phone_number"
          :state="formStates.customer_phone_number"
        >
          <b-input
            id="customer-phone-number-input"
            v-model="formData.customer_phone_number"
            :state="formStates.customer_phone_number"
            autocomplete="phone"
            @input="formErrors.customer_phone_number = null"
            trim
          />
        </b-form-group>

        <b-form-group
          id="fulfilment-slot-id"
          description="Choose a collection time."
          label="Choose a time to collect your order"
          valid-for="fulfilment-slot-id-input"
          :invalid-feedback="formErrors.fulfilment_slot_id"
          :state="formStates.fulfilment_slot_id"
        >
          <b-select
            v-model="formData.fulfilment_slot_id"
            :options="fulfilmentSlotOptions"
            :state="formStates.fulfilment_slot_id"
            @input="formErrors.fulfilment_slot_id = null"
          />
        </b-form-group>

        <b-form-group
          id="payment-method"
          description="Choose how you want to pay."
          label="Choose payment"
          valid-for="payment-method-input"
          :invalid-feedback="formErrors.pay_online"
          :state="formStates.pay_online"
        >
          <b-form-radio-group
            v-model="formData.pay_online"
            :state="formStates.pay_online"
            :options="payOnlineOptions"
            @input="formErrors.pay_online = null"
            name="payment-method"
            stacked
            size="lg"
          />
        </b-form-group>

        <b-alert v-if="formErrorMessage" show variant="danger">
          {{ formErrorMessage }}
        </b-alert>

        <b-button
          type="submit"
          variant="primary"
          @click="submitOrder"
          :disabled="isSubmitting"
          ><span v-if="isSubmitting"
            >{{
              formData.pay_online === true
                ? "Proceeding to payment..."
                : "Placing order..."
            }}
          </span>
          <span v-else>{{
            formData.pay_online === true ? "Proceed to payment" : "Place order"
          }}</span></b-button
        >
      </div>

      <div>
        If you need help please call the pub on
        <a href="tel:01986 892681">01986 892681</a>.
      </div>
    </div>
  </div>
</template>

<script>
const formatInterval = require("../lib/format-interval.js");
import { to } from "await-to-js";

export default {
  props: {
    menu: {
      type: Object,
      required: true,
    },
    // TODO: Possibly filter to collection only if Green Dragon adds delivery.
    initialFulfilmentSlots: {
      type: Array,
      required: true,
    },
    // Set after successful order submission.
    placedOrder: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: function () {
    return {
      fulfilmentSlots: [...this.initialFulfilmentSlots],
      menuProductLines: this.getInitialMenuProductLines(),
      formErrorMessage: null,
      formData: {
        customer_name: "",
        customer_phone_number: "",
        fulfilment_slot_id: null,
        pay_online: null,
      },
      formErrors: this.getInitialFormErrors(),
      isSubmitting: false,
    };
  },
  computed: {
    collectionTimes: function () {
      return this.fulfilmentSlots.map((s) => ({
        id: s.id,
        value: this.$upperFirst(formatInterval(s.starts_at, s.ends_at)),
      }));
    },
    menuProductLinesOrder() {
      return this.menuProductLines
        .map((menuProductLine) => ({
          id: menuProductLine.id,
          quantity: menuProductLine.quantity,
        }))
        .filter((menuProductLine) => menuProductLine.quantity > 0);
    },
    menuProductLinesInBag() {
      return this.menuProductLines.filter((menuProductLine) => {
        return menuProductLine.quantity > 0;
      });
    },
    payOnlineOptions() {
      return [
        { text: "Cash or Card payment on collection", value: false },
        { text: "Card payment online with Stripe", value: true },
      ];
    },
    fulfilmentSlotOptions() {
      return [
        { text: "Select collection time", value: null, disabled: true },
        ...this.collectionTimes.map((collectionTime) => ({
          text: collectionTime.value,
          value: collectionTime.id,
        })),
      ];
    },
    formStates() {
      return this.$mapValues(this.formErrors, (error) => {
        return error ? false : null;
      });
    },
    hasErrors() {
      return Object.values(this.formErrors).some((error) => error !== null);
    },
  },
  methods: {
    getInitialFormErrors() {
      return {
        customer_name: null,
        customer_phone_number: null,
        fulfilment_slot_id: null,
        pay_online: null,
      };
    },
    getInitialMenuProductLines() {
      const productsById = this.$chain(this.menu.menu_display_groups)
        .flatMap("menu_products")
        .map("product")
        .keyBy("id")
        .value();

      return this.$chain(this.menu.menu_display_groups)
        .flatMap("menu_products")
        .flatMap("menu_product_lines")
        .uniqBy("id")
        .map((menuProductLine) => ({
          ...menuProductLine,
          product: productsById[menuProductLine.product_line.product_id],
          quantity: 0,
        }))
        .value();
    },
    checkForm() {
      if (this.formData.customer_name === "") {
        this.formErrors.customer_name = "Please enter your name.";
      } else {
        this.formErrors.customer_name = null;
      }

      if (this.formData.customer_phone_number === "") {
        this.formErrors.customer_phone_number =
          "Please enter your phone number.";
      } else {
        this.formErrors.customer_phone_number = null;
      }

      if (this.formData.fulfilment_slot_id === null) {
        this.formErrors.fulfilment_slot_id = "Please choose a collection time.";
      } else {
        this.formErrors.fulfilment_slot_id = null;
      }

      if (this.formData.pay_online === null) {
        this.formErrors.pay_online = "Please choose how you will pay.";
      } else {
        this.formErrors.pay_online = null;
      }
    },
    resetFormErrors() {
      this.formErrors = this.getInitialFormErrors();
      this.formErrorMessage = null;
    },
    async submitOrder() {
      this.checkForm();
      if (this.hasErrors) {
        return;
      }

      this.resetFormErrors();
      this.isSubmitting = true;

      const [err, result] = await to(
        this.$api.post("checkout", {
          ...this.formData,
          menu_product_line_quantities: this.menuProductLinesInBag.map(
            (menuProductLine) => ({
              menu_product_line_id: menuProductLine.id,
              quantity: menuProductLine.quantity,
            })
          ),
        })
      );

      this.isSubmitting = false;

      if (err) {
        this.formErrors = { ...this.formErrors, ...err.response.data.errors };

        if (
          err.response.data.errors === undefined ||
          Object.keys(err.response.data.errors).length === 0
        ) {
          this.formErrorMessage = `Sorry, something went wrong placing the order.`;
        }

        if (err.response.data.fulfilmentSlots) {
          this.fulfilmentSlots = err.response.data.fulfilmentSlots;
          this.formData.fulfillment_slot_id = null;
        }

        return;
      }

      // Success.
      switch (result.data.action) {
        case "redirect":
          // Stripe checkout.
          window.location = result.data.url;
          break;
        case "reload":
          // Cash on collection.
          location.reload();
          break;
      }
    },
  },
  watch: {
    async "formData.fulfilment_slot_id"(newValue) {
      await this.$api.put("fulfilment-slot-id", {
        fulfilmentSlotId: newValue,
      });
    },
  },
  components: {},
  mounted() {
    if (this.$refs.placedOrder) {
      console.log(`Scrolling to "Thank you" message.`);
      setTimeout(() => window.scrollTo(0, 0), 50);
    }
  },
};
</script>

<style lang="scss" scoped>
.gdbeer-index {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: 300;

  .products {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    .product-cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      gap: 0.5rem;
    }
  }

  .box {
    --border-width: 3px;
    padding: 0.5rem 4rem;
    border-radius: 15px;
    background-color: rgba(0, 0, 0, 0.1);
    font-size: 2rem;
    margin: 0;

    &:not(.gradient-border) {
      border: var(--border-width) solid var(--stroke-color);
    }

    // From https://codepen.io/mike-schultz/pen/NgQvGO
    &.gradient-border {
      position: relative;
      border-radius: var(--border-width);
    }

    &.gradient-border:after {
      content: "";
      position: absolute;
      top: calc(-1 * var(--border-width));
      left: calc(-1 * var(--border-width));
      height: calc(100% + var(--border-width) * 2);
      width: calc(100% + var(--border-width) * 2);
      background: linear-gradient(
        60deg,
        #f79533,
        #f37055,
        #ef4e7b,
        #a166ab,
        #5073b8,
        #1098ad,
        #07b39b,
        #6fba82
      );
      border-radius: calc(2 * var(--border-width));
      z-index: -1;
      animation: animatedgradient 3s ease alternate infinite;
      background-size: 300% 300%;
    }
  }

  .checkout-success,
  .instructions,
  .basket {
    font-size: 1.5rem;

    ol {
      margin: 0;
      padding: 0;
    }

    @media (max-width: 750px) {
      font-size: 1.5rem;
      padding: 0.5rem 3rem;
    }

    @media (max-width: 525px) {
      font-size: 1.25rem;
      padding: 0.5rem 2rem;
    }
  }

  .basket {
    display: grid;
    grid-template-columns: 5fr 1fr 2fr;
  }

  .basket-item {
    display: contents;
  }

  .checkout-success {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: black;
    background-position: 0 70px;
    background-repeat: no-repeat;
  }

  .basket-and-order-form {
    width: 100%;
    max-width: 800px;
  }

  .controls {
    font-size: 3rem;
    margin-left: 1em;
    margin-right: 1em;
    margin-top: 0.5em;
  }
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
