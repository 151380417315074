<template>
  <!--
  TODO: Is it possible to bind listeners but not have @click fire twice?
    v-on="$listeners"
  -->
  <a :href="disabled ? null : href" v-if="href !== null">
    <!-- TODO: This is duplicated. It could be moved to a separate component. -->
    <button
      v-bind="$attrs"
      class="ws-button"
      :disabled="disabled"
      :class="{
        borderless,
        disabled,
        [`variant-${variant}`]: true,
        block,
        [`size-${size}`]: true,
        selected,
      }"
      @click.stop="disabled ? null : $emit('click', $event)"
    >
      <slot />
    </button>
  </a>
  <button
    v-else
    v-bind="$attrs"
    class="ws-button"
    :disabled="disabled"
    :class="{
      borderless,
      disabled,
      [`variant-${variant}`]: true,
      block,
      selected,
    }"
    @click.stop="disabled ? null : $emit('click', $event)"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      default: "primary",
    },
    borderless: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: null,
    },
    block: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "md",
      validator: (value) => ["sm", "md", "lg"].includes(value),
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  async created() {},
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}

.ws-button {
  background-color: transparent;
  border: 1px solid var(--light-grey);
  padding: 0.4rem 0.75rem;
  border-radius: 5px;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }

  &.variant-cta {
    border: 2px solid black;
    border-radius: 8px;
    font-weight: bold;
    letter-spacing: 0.5px;

    &.disabled {
      color: var(--dark-grey);
    }
  }

  &.variant-cta-inverted {
    background-color: black;
    color: white;
    border-radius: 8px;
    font-weight: bold;
    letter-spacing: 0.5px;

    &.disabled {
      color: var(--light-grey);
    }
  }

  &.variant-quantity-adjustment {
    font-family: "Merriweather Sans";
    border: none;
    background-color: var(--light-grey);

    &.disabled {
      background-color: var(--very-light-grey);
    }
  }

  &.variant-secondary {
    background-color: var(--light-grey);
    border: 1px solid var(--light-grey);
    color: black;

    &.disabled {
      background-color: var(--very-light-grey);
      border: 1px solid var(--very-light-grey);
      color: var(--grey);
    }
  }

  &.borderless {
    border: none;
  }

  &.block {
    display: block;
    width: 100%;
  }

  &.size-lg {
    padding: 0.7rem 1rem;
  }

  &.selected {
    border: 1px solid var(--dark-grey);
    outline: 1px solid var(--dark-grey);
    outline-offset: -2px;
  }
}
</style>
