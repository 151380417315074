const dateFns = require("date-fns");

module.exports = (startTime, endTime) => {
  const start = dateFns.parseISO(startTime);
  const end = dateFns.parseISO(endTime);

  let pieces = [];

  // Date piece.
  if (dateFns.isToday(start)) {
    pieces.push("today");
  } else if (dateFns.isTomorrow(start)) {
    pieces.push("tomorrow");
  } else {
    pieces.push(dateFns.format(start, "E do MMM,"));
  }

  pieces.push("between");
  pieces.push(dateFns.format(start, "h.mmaaaaa'm'"));
  pieces.push("and");
  pieces.push(dateFns.format(end, "h.mmaaaaa'm'"));
  return pieces.join(" ");
};
