var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ws-simple-page",
    {
      attrs: {
        title: "Sorry, we are closed right now.",
        icon: "bowl-rice",
        "icon-color": "red",
      },
    },
    [_vm._v("\n  Don't worry, we'll be open again soon.\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }