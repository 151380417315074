<template>
  <div
    class="product-image"
    :style="{
      minWidth: computedWidth,
      minHeight: computedHeight,
    }"
  >
    <ws-image
      v-if="product.image_url"
      :value="product"
      :width="width"
      :height="height"
    />
    <font-awesome-icon
      v-else
      :icon="$missingProductIcon"
      :style="{
        color: 'red',
        width: computedIconWidth,
        height: computedIconHeight,
      }"
    />
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
    width: {
      type: Number,
      default: null,
    },
    height: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    computedWidth() {
      if (this.width) {
        return `${this.width}px`;
      }

      return "100%";
    },
    computedHeight() {
      if (this.height) {
        return `${this.height}px`;
      }

      return "100%";
    },
    computedIconWidth() {
      if (this.width) {
        return `${this.width * 0.5}px`;
      }

      return "50%";
    },
    computedIconHeight() {
      if (this.height) {
        return `${this.height * 0.5}px`;
      }

      return "50%";
    },
  },
  watch: {},
  async created() {},
};
</script>

<style lang="scss" scoped>
.product-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
