import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faMagnifyingGlass,
  faCartShopping,
  faCircleXmark,
  faTag,
  faBowlFood,
  faBowlRice,
  faCaretLeft,
  faTrash,
  faClock,
  faHammer,
} from "@fortawesome/free-solid-svg-icons";

import {
  faCcApplePay,
  faGooglePay,
  faCcVisa,
  faCcMastercard,
  faCcAmex,
  faStripe,
} from "@fortawesome/free-brands-svg-icons";

export default {
  install(Vue) {
    // eslint-disable-next-line vue/component-definition-name-casing
    Vue.component("font-awesome-icon", FontAwesomeIcon);

    library.add(
      faMagnifyingGlass,
      faCartShopping,
      faCircleXmark,
      faTag,
      faBowlFood,
      faBowlRice,
      faCaretLeft,
      faTrash,
      faCcApplePay,
      faGooglePay,
      faCcVisa,
      faCcMastercard,
      faCcAmex,
      faClock,
      faStripe,
      faHammer
    );
  },
};
