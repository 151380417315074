<template>
  <div
    class="image"
    :style="{
      width: computedWidth,
      height: computedHeight,
    }"
  >
    <img
      v-if="value.image_url"
      :src="value.image_url"
      :srcset="value.image_srcset"
      :width="width"
      :height="height"
      :style="{
        width: computedWidth,
        height: computedHeight,
      }"
    />
  </div>
</template>

<script>
export default {
  props: {
    // TODO: Probably split this into imageUrl and imgSrcset attributes, since
    // they may not always be nested on the same object.
    value: {
      required: true,
      type: Object,
    },
    width: {
      required: false,
      type: Number,
      default: null,
    },
    height: {
      required: false,
      type: Number,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    computedWidth() {
      if (this.width) {
        return `${this.width}px`;
      }

      return "100%";
    },
    computedHeight() {
      if (this.height) {
        return `${this.height}px`;
      }

      return "100%";
    },
  },
  watch: {},
  async created() {},
};
</script>

<style lang="scss" scoped>
.image {
  img {
    // Sometimes we want to contain, sometimes we want to cover.
    // How do we decide?
    // Green Dragon pump badges have transparent background. We want to contain them.
    // Woktruck food photos are full bleed. We want to cover them.
    object-fit: cover;
  }
}
</style>
