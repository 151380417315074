<template>
  <div class="simple-page">
    <h1>
      <slot name="title">{{ title }}</slot>
    </h1>
    <h3><slot /></h3>
    <div v-if="icon">
      <font-awesome-icon :icon="icon" size="3x" :style="{ color: iconColor }" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: null,
    },
    iconColor: {
      type: String,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  async created() {},
};
</script>

<style lang="scss" scoped>
.simple-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 2rem;
  padding: 2rem;
}
</style>
