var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "quantity-buttons" },
    [
      _c(
        "ws-button",
        {
          attrs: {
            variant: "quantity-adjustment",
            disabled: _vm.disabled || _vm.value === 0,
          },
          on: {
            click: function ($event) {
              _vm.$emit("input", _vm.value - 1)
              _vm.$emit("decrement")
            },
          },
        },
        [_vm._v("-")]
      ),
      _vm._v(" "),
      _c("ws-button", { attrs: { borderless: "" } }, [
        _vm._v(_vm._s(_vm.value)),
      ]),
      _vm._v(" "),
      _c(
        "ws-button",
        {
          attrs: { variant: "quantity-adjustment", disabled: _vm.disabled },
          on: {
            click: function ($event) {
              _vm.$emit("input", _vm.value + 1)
              _vm.$emit("increment")
            },
          },
        },
        [_vm._v("+")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }