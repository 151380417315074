var render = function () {
  var _obj, _obj$1
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.href !== null
    ? _c("a", { attrs: { href: _vm.disabled ? null : _vm.href } }, [
        _c(
          "button",
          _vm._b(
            {
              staticClass: "ws-button",
              class:
                ((_obj = {
                  borderless: _vm.borderless,
                  disabled: _vm.disabled,
                }),
                (_obj["variant-" + _vm.variant] = true),
                (_obj.block = _vm.block),
                (_obj["size-" + _vm.size] = true),
                (_obj.selected = _vm.selected),
                _obj),
              attrs: { disabled: _vm.disabled },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  _vm.disabled ? null : _vm.$emit("click", $event)
                },
              },
            },
            "button",
            _vm.$attrs,
            false
          ),
          [_vm._t("default")],
          2
        ),
      ])
    : _c(
        "button",
        _vm._b(
          {
            staticClass: "ws-button",
            class:
              ((_obj$1 = {
                borderless: _vm.borderless,
                disabled: _vm.disabled,
              }),
              (_obj$1["variant-" + _vm.variant] = true),
              (_obj$1.block = _vm.block),
              (_obj$1.selected = _vm.selected),
              _obj$1),
            attrs: { disabled: _vm.disabled },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                _vm.disabled ? null : _vm.$emit("click", $event)
              },
            },
          },
          "button",
          _vm.$attrs,
          false
        ),
        [_vm._t("default")],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }