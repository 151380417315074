<!--
  This is the "add to cart" modal for a product.

  Note that the behaviour is different for products with options.
  * When adding to cart, the user is required to choose the product options.
  * When removing from the cart, the last added menu product line (with its options) is removed.
-->
<template>
  <ws-modal class="ws-product-modal" v-on="$listeners">
    <template #hero>
      <ws-product-image :height="400" :product="product" />
    </template>

    <div class="content">
      <div class="title">{{ product.name }}</div>
      <div class="price">{{ menuProductPriceStr }}</div>
      <div class="description">{{ product.web_description }}</div>

      <div class="menu-product-lines" v-if="isChoosingOptions === false">
        <div
          class="menu-product-line"
          v-for="menuProductLine in menuProduct.menu_product_lines"
          :key="menuProductLine.id"
        >
          <ws-quantity-buttons
            :value="localBag.getQuantity(menuProductLine)"
            @decrement="localBag.removeMenuProductLine(menuProductLine)"
            @increment="handleIncrementMenuProductLine(menuProductLine)"
          />
          <div>
            {{ menuProductLine.product_line.product_measure.web_label }}
          </div>
          <div v-if="hasMultipleProductLines">
            {{ $formatCurrency(menuProductLine.price) }}
          </div>
          <div v-if="hasOptions">Add to cart to choose options</div>
        </div>
      </div>
      <div v-else class="opacity-20">
        <div
          class="menu-product-line"
          v-for="menuProductLine in menuProduct.menu_product_lines"
          :key="menuProductLine.id"
        >
          <ws-quantity-buttons
            :value="localBag.getQuantity(menuProductLine)"
            disabled
          />
          <div>
            {{ menuProductLine.product_line.product_measure.web_label }}
          </div>
          <div v-if="hasMultipleProductLines">
            {{ $formatCurrency(menuProductLine.price) }}
          </div>
        </div>
      </div>

      <!-- Product line options. -->
      <transition name="fade">
        <div
          class="product-options"
          :class="{ ['opacity-20']: isChoosingOptions === false }"
        >
          <div
            class="product-option"
            v-for="productOption in product.product_options"
            :key="productOption.id"
          >
            <div>
              <h2>
                {{ productOption.name
                }}<span class="product-option-requirement"
                  >&nbsp; {{ productOptionRequirementStr(productOption) }}</span
                >
              </h2>
            </div>

            <!-- Quantity inputs when the user can choose multiple, e.g. toppings. -->
            <!-- TODO: Handle maximum choices. -->
            <div class="product-option-choices">
              <div
                v-for="productLine in productOption.product_lines"
                :key="productLine.id"
                class="product-option-choice"
              >
                <ws-button
                  @click="handleChooseOption(productOption, productLine)"
                  :selected="
                    productOptionProductLineIsChosen(productOption, productLine)
                  "
                  :disabled="
                    isChoosingOptions === false ||
                    (productOptionProductLineIsChosen(
                      productOption,
                      productLine
                    ) === false &&
                      hasChosenMaximumQuantityForProductOption(productOption) &&
                      productOption.maximum_choices > 1)
                  "
                  >{{ productLine.product.name }}&nbsp;<span
                    v-if="productLine.price > 0"
                    >{{ $formatCurrency(productLine.price) }}</span
                  ></ws-button
                >
              </div>

              <div v-if="productOption.is_declinable">
                <ws-button
                  @click="declineOption(productOption)"
                  :selected="optionIsDeclined(productOption)"
                  >No {{ productOption.name }}</ws-button
                >
              </div>
            </div>
          </div>
        </div>
      </transition>

      <div class="actions">
        <ws-button
          v-if="isChoosingOptions"
          class="add-to-order-button"
          variant="cta"
          @click="finishChoosingOptions()"
          :disabled="canConfirmChoices === false"
        >
          <!-- TODO: Show total including choices. -->
          Confirm {{ $pluralize("choice", product.product_options.length) }}
          {{ $formatCurrency(selectedOptionTotalPrice) }}
        </ws-button>

        <ws-button
          v-else
          class="add-to-order-button"
          variant="cta-inverted"
          @click="updateAndClose()"
          :disabled="wasInBag === false && isInBag === false"
        >
          <template v-if="wasInBag">
            <template v-if="localTotalQuantity === 0"
              >Remove from order</template
            >
            <template v-else
              >Update order {{ $formatCurrency(localTotal) }}</template
            >
          </template>
          <template v-else>
            <template v-if="localTotalQuantity === 0">Choose quantity</template>
            <template v-else
              >Add to order {{ $formatCurrency(localTotal) }}</template
            >
          </template>
        </ws-button>

        <ws-button class="close-button" @click="$emit('close')" borderless>
          <font-awesome-icon icon="circle-xmark" />
        </ws-button>
      </div>
    </div>
  </ws-modal>
</template>

<script>
import { Bag } from "@/lib/bag-utils.js";

export default {
  props: {
    menuProductLinesInBag: {
      type: Array,
      required: true,
    },
    menuProduct: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      // We need a copy, because it's updated locally then emitted once the
      // user accepts the changes.
      localMenuProductLinesInBag: [...this.menuProductLinesInBag],
      selectedMenuProductLineForOptions: null,
      // selectedOptions is an array of objects:
      // [{
      //   product_option_id,
      //   product_line_id,
      //   quantity,
      // }]
      selectedOptions: [],
      isClosing: false,
      cachedLocalTotal: null,
      cachedLocalTotalQuantity: null,
      cachedWasInBag: null,
    };
  },
  computed: {
    localBag() {
      return new Bag(this.localMenuProductLinesInBag);
    },
    product() {
      return this.menuProduct.product;
    },
    originalBag() {
      return new Bag(this.menuProductLinesInBag);
    },
    wasInBag() {
      if (this.isClosing) {
        return this.cachedWasInBag;
      }

      return this.originalBag.hasSomeMenuProductLines(
        this.menuProduct.menu_product_lines
      );
    },
    isInBag() {
      return this.localBag.hasSomeMenuProductLines(
        this.menuProduct.menu_product_lines
      );
    },
    hasOptions() {
      return this.menuProduct.product.product_options.length > 0;
    },
    hasMultipleProductLines() {
      return this.menuProduct.menu_product_lines_count > 1;
    },
    menuProductPriceStr() {
      const minPriceStr = this.$formatCurrency(
        this.menuProduct.min_menu_product_line_price
      );

      if (this.hasMultipleProductLines === false) {
        return minPriceStr;
      }

      return `From ${minPriceStr}`;
    },
    menuProductLinesById() {
      return this.$chain(this.menuProduct.menu_product_lines)
        .keyBy("id")
        .value();
    },
    menuProductLinesIds() {
      return this.$map(this.menuProduct.menu_product_lines, "id");
    },
    localTotal() {
      if (this.isClosing) {
        return this.cachedLocalTotal;
      }

      return this.$chain(this.localMenuProductLinesInBag)
        .filter((bagItem) =>
          this.menuProductLinesIds.includes(bagItem.menu_product_line_id)
        )
        .map((bagItem) => this.getBagItemPrice(bagItem))
        .sum()
        .value();
    },
    localTotalQuantity() {
      if (this.isClosing) {
        return this.cachedLocalTotalQuantity;
      }

      return this.$chain(this.localMenuProductLinesInBag)
        .filter((bagItem) =>
          this.menuProductLinesIds.includes(bagItem.menu_product_line_id)
        )
        .map((bagItem) => bagItem.quantity)
        .sum()
        .value();
    },
    isChoosingOptions() {
      return this.selectedMenuProductLineForOptions !== null;
    },
    canConfirmChoices() {
      for (const productOption of this.product.product_options) {
        // Need a choice for each required option, even if it is declined.
        if (
          this.$some(this.selectedOptions, {
            product_option_id: productOption.id,
          }) === false
        ) {
          return false;
        }
      }

      return true;
    },
    productLinesById() {
      const menuProductProductLines = this.$chain(
        this.menuProduct.menu_product_lines
      )
        .map("product_line")
        .keyBy("id")
        .value();

      const productOptionProductLines = this.$chain(
        this.menuProduct.product.product_options
      )
        .flatMap("product_lines")
        .keyBy("id")
        .value();

      return {
        ...menuProductProductLines,
        ...productOptionProductLines,
      };
    },
    selectedOptionTotalPrice() {
      if (this.selectedMenuProductLineForOptions === null) {
        return null;
      }

      return (
        this.$chain(this.selectedOptions)
          .reject({ is_declined: true })
          .map(
            (selectedOption) =>
              selectedOption.quantity *
              this.productLinesById[selectedOption.product_line_id].price
          )
          .sum()
          .value() + this.selectedMenuProductLineForOptions.price
      );
    },
  },
  methods: {
    updateAndClose() {
      // This a funny way to stop the component changing as it closes.
      this.cachedLocalTotal = this.localTotal;
      this.cachedLocalTotalQuantity = this.localTotalQuantity;
      this.cachedWasInBag = this.wasInBag;
      this.isClosing = true;

      this.$emit(
        "update:menu-product-lines-in-bag",
        this.localMenuProductLinesInBag
      );
      this.$emit("close");
    },
    handleIncrementMenuProductLine(menuProductLine) {
      // For products that have options, when the quantity increases, options
      // must be selected.

      // Adding item.
      if (this.hasOptions) {
        this.selectedMenuProductLineForOptions = menuProductLine;
      } else {
        this.localBag.addMenuProductLine(menuProductLine);
      }
    },
    productOptionRequirementStr(productOption) {
      const pieces = [];

      if (productOption.maximum_choices === 1) {
        pieces.push("choose one");
      } else {
        // TODO: Will probably remove this, just for info during testing.
        pieces.push(`upto ${productOption.maximum_choices}`);
      }

      if (productOption.is_declinable === true) {
        pieces.push("optional");
      }

      return pieces.join(", ");
    },
    finishChoosingOptions() {
      this.localBag.addMenuProductLine(
        this.selectedMenuProductLineForOptions,
        Object.entries(this.selectedOptions).map(
          ([productOptionId, option]) => ({
            product_option_id: parseInt(productOptionId),
            ...option,
          })
        )
      );

      this.selectedMenuProductLineForOptions = null;
      this.selectedOptions = [];
    },
    productOptionProductLineIsChosen(productOption, productLine) {
      return this.$some(this.selectedOptions, {
        product_option_id: productOption.id,
        product_line_id: productLine.id,
      });
    },
    quantityForProductOption(productOption) {
      return this.$chain(this.selectedOptions)
        .filter({ product_option_id: productOption.id })
        .sumBy("quantity")
        .value();
    },
    hasChosenMaximumQuantityForProductOption(productOption) {
      return (
        this.quantityForProductOption(productOption) >=
        productOption.maximum_choices
      );
    },
    declineOption(productOption) {
      // Remove previously selected options.
      this.selectedOptions = this.$reject(this.selectedOptions, {
        product_option_id: productOption.id,
      });

      this.selectedOptions.push({
        product_option_id: productOption.id,
        is_declined: true,
      });
    },
    undeclineOption(productOption) {
      this.selectedOptions = this.$reject(this.selectedOptions, {
        product_option_id: productOption.id,
        is_declined: true,
      });
    },
    optionIsDeclined(productOption) {
      return this.$some(this.selectedOptions, {
        product_option_id: productOption.id,
        is_declined: true,
      });
    },
    handleChooseOption(productOption, productLine) {
      // Customer has clicked on a product line for a product option.
      // Note that some product options allow multiple product lines to be chosen.
      // For now, we only support a maximum quantity of 1 for each product line.
      const selectedOptionToFind = {
        product_option_id: productOption.id,
        product_line_id: productLine.id,
      };

      const selectedOption = this.$find(
        this.selectedOptions,
        selectedOptionToFind
      );

      // Clicking on an already selected option deselects it.
      if (selectedOption) {
        this.selectedOptions = this.$reject(
          this.selectedOptions,
          selectedOptionToFind
        );
        return;
      }

      // Clicked on an unselected option.
      this.undeclineOption(productOption);

      if (productOption.maximum_choices === 1) {
        // Only a single option, allow toggling between them.
        this.selectedOptions = this.$reject(this.selectedOptions, {
          product_option_id: productOption.id,
        });
      }

      if (
        this.hasChosenMaximumQuantityForProductOption(productOption) === false
      ) {
        // TODO: Could flash a warning here (although the button is disabled).
        this.selectedOptions.push({ ...selectedOptionToFind, quantity: 1 });
      }
    },
    getBagItemPrice(bagItem) {
      // TODO: Factor this out.
      const getOptionsPrice = (options) =>
        this.$chain(options)
          .reject({ is_declined: true })
          .map((option) => this.productLinesById[option.product_line_id].price)
          .sum();

      return (
        bagItem.quantity *
        (this.menuProductLinesById[bagItem.menu_product_line_id].price +
          getOptionsPrice(bagItem.options))
      );
    },
  },
  watch: {},
  async created() {},
};
</script>

<style lang="scss" scoped>
.ws-product-modal {
  .content {
    display: flex;
    flex-direction: column;
    gap: 1.5em;

    .title {
      font-size: 2em;
      font-weight: bold;
    }

    .price {
      font-size: 1.25em;
    }

    .description {
      line-height: 1.6em;
    }
  }

  .actions {
    display: flex;
    gap: 0.5em;

    .close-button {
      font-size: 1.5em;
    }

    .add-to-order-button {
      width: 100%;
    }
  }

  .menu-product-lines {
    display: flex;
    flex-direction: column;
    gap: 1em;

    .menu-product-line {
      display: flex;
      flex-direction: row;
      gap: 1em;
      align-items: center;
    }
  }

  .product-options {
    display: flex;
    flex-direction: column;
    display: flex;
    gap: 1.5em;

    .product-option {
      display: flex;
      flex-direction: column;
      gap: 1em;

      .product-option-choices {
        display: flex;
        gap: 0.5em;
        flex-wrap: wrap;
      }
    }
  }

  .product-option-requirement {
    margin-left: 1em;
    font-size: 0.8em;
    color: var(--dark-grey);
    letter-spacing: 1px;
    word-spacing: 2px;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}

.opacity-20 {
  opacity: 0.2;
}
</style>
