<template>
  <!-- TODO: Should really "fit" most of these elements. -->

  <div class="gdbeer-product-card" :style="{ ['--width']: `${width}px` }">
    <transition name="flipped" mode="out-in">
      <div
        class="product-card-front"
        :class="{ [`cursor-pointer`]: info !== null }"
        v-if="flipped === false"
        @click="info !== null ? (flipped = !flipped) : null"
        key="flipped"
      >
        <div v-if="imageUrl" class="product-card-image">
          <img :src="imageUrl" :srcset="imageSrcset" />
        </div>

        <div v-else class="product-card-image">
          <img :src="noImg" />
        </div>

        <div class="product-card-title-first-line">
          {{ title.split(" ")[0] }}
        </div>

        <div class="product-card-title-rest-lines">
          {{ title.split(" ").slice(1).join(" ") }}
        </div>

        <div class="price">
          {{ $formatCurrency(price) }}
        </div>

        <div class="product-measure-label">
          {{ productMeasureWebLabel }}
        </div>

        <div v-if="abv !== null" class="abv">{{ $round(abv * 100) }}%</div>

        <div class="product-card-quantity">
          {{ quantity }}
        </div>

        <div class="click-for-info" v-if="info">click for more info</div>

        <div class="product-card-minus" @click="decrementquantity"></div>
        <div class="product-card-plus" @click="incrementquantity"></div>
      </div>
      <div
        v-else
        class="cursor-pointer product-card-back"
        @click="flipped = !flipped"
      >
        <div class="product-card-info">
          {{ info }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import noImg from "./../img/no-image.png";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    abv: {
      required: true,
      validator: (value) => {
        return value === null || (typeof value === "number" && value >= 0);
      },
    },
    imageUrl: {
      type: String,
      required: false,
      default: null,
    },
    imageSrcset: {
      type: String,
      required: false,
      default: null,
    },
    info: {
      type: String,
      default: null,
    },
    productMeasureWebLabel: {
      type: String,
      default: null,
    },
    width: {
      type: Number,
      required: true,
    },
  },
  data: function () {
    return {
      quantity: 0,
      noImg,
      flipped: false,
    };
  },
  methods: {
    decrementquantity: function (e) {
      e.stopPropagation();
      if (this.quantity === 0) {
        return;
      }

      this.quantity -= 1;
    },
    incrementquantity: function (e) {
      e.stopPropagation();
      this.quantity += 1;
    },
  },
  watch: {
    quantity(newVal) {
      this.$emit("input", newVal);
    },
  },
};
</script>

<style lang="scss" scoped>
.gdbeer-product-card {
  --small-font-size: calc(var(--width) * 0.06);
  --medium-font-size: calc(var(--width) * 0.08);

  .product-card {
    width: var(--width);
    height: calc(var(--width) * 1.4);
    color: var(--background-color);

    background-size: 100% 100%;
    position: relative;
    font-family: "Permanent Marker", sans-serif;
    margin-bottom: 0.5em;

    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: linear;
    transition-delay: 0s;
  }

  .product-card-front {
    @extend .product-card;
    background-image: url(../img/product-card-front@2x.png);
  }

  .product-card-back {
    @extend .product-card;
    background-image: url(../img/product-card-back@2x.png);
  }

  .product-card-title {
    position: absolute;

    text-align: center;
    font-size: calc(var(--width) * 0.1);
    line-height: 1em;
    text-shadow: -1px -1px 0 #fff, 0 -1px 0 #fff, 1px -1px 0 #fff, 1px 0 0 #fff,
      1px 1px 0 #fff, 0 1px 0 #fff, -1px 1px 0 #fff, -1px 0 0 #fff;

    box-sizing: border-box;
    width: 100%;
  }

  .product-card-info {
    position: absolute;
    top: calc(var(--width) * 0.2);
    height: calc(var(--width) * 1.1);

    text-align: center;
    font-size: calc(var(--width) * 0.1);
    line-height: 1em;

    overflow: hidden;
    text-overflow: ellipsis;

    box-sizing: border-box;
    width: 100%;

    padding-left: 8%;
    padding-right: 8%;
  }

  .product-card-title-first-line {
    @extend .product-card-title;
    top: calc(var(--width) * 0.08);
    padding-left: 15%;
    padding-right: 15%;
  }

  .product-card-title-rest-lines {
    @extend .product-card-title;
    top: calc(var(--width) * 0.2);
    padding-left: 8%;
    padding-right: 8%;
  }

  .product-card-footer {
    position: absolute;
    bottom: calc(var(--width) * 0.4);

    font-size: var(--medium-font-size);

    box-sizing: border-box;
    width: 100%;
    padding-left: 6%;
    padding-right: 6%;
  }

  .price {
    @extend .product-card-footer;
    font-size: var(--small-font-size);
    text-align: left;
  }

  .product-measure-label {
    @extend .product-card-footer;
    font-size: var(--small-font-size);
    text-align: center;
    margin: auto;
    bottom: calc(var(--width) * 0.4);
  }

  .click-for-info {
    @extend .product-card-footer;
    text-align: center;
    font-size: var(--small-font-size);
    bottom: calc(var(--width) * 0.32);
    -webkit-text-stroke-width: 0px;
  }

  .abv {
    @extend .product-card-footer;
    font-size: var(--small-font-size);
    text-align: right;
  }

  .product-card-image {
    position: absolute;
    bottom: calc(var(--width) * 0.3);
    box-sizing: border-box;
    width: 100%;
    height: 200px;
    padding-left: 10%;
    padding-right: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .product-card-image > img {
    width: calc(var(--width) * 0.5);
    height: calc(var(--width) * 0.5);
  }

  .product-card-quantity {
    position: absolute;
    bottom: calc(var(--width) * -0.03);

    text-align: center;
    font-size: calc(var(--width) * 0.25);

    box-sizing: border-box;
    width: 100%;

    /* For some reason the text doesn't look centered with even padding */
    padding-left: 12%;
    padding-right: 10%;

    font-family: "Rubik Mono One", sans-serif;
  }

  .product-card-button {
    position: absolute;
    cursor: pointer;

    position: absolute;
    bottom: 0;
    width: calc(var(--width) * 0.3);
    height: calc(var(--width) * 0.3);
  }

  .product-card-minus {
    @extend .product-card-button;
    left: 10px;
  }

  .product-card-plus {
    @extend .product-card-button;
    right: 10px;
  }

  .flipped-leave-to {
    transform: rotate3d(0, 1, 0, 90deg);
  }

  .flipped-enter {
    transform: rotate3d(0, 1, 0, -90deg);
  }

  .cursor-pointer {
    cursor: pointer;
  }
}
</style>
