var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "input",
    _vm._b(
      {
        staticClass: "ws-input",
        domProps: { value: _vm.value },
        on: {
          input: function (event) {
            return _vm.$emit("input", event.target.value)
          },
        },
      },
      "input",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }