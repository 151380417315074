export default {
  install(Vue) {
    Vue.prototype.$formatCurrency = (value) => {
      return Intl.NumberFormat("en-UK", {
        style: "currency",
        currency: "GBP",
      }).format(value);
    };

    Vue.prototype.$friendlyFormatCurrency = (amountInPounds) => {
      // Format a currency (provided as an integer number of pennies) in a
      // freindlier way that Intl.NumberFormat. That is, display 0.55 as 55p
      // instead of £0.55.
      if (amountInPounds >= 1 || amountInPounds < 0) {
        return Vue.prototype.$formatCurrency(amountInPounds);
      }

      if (amountInPounds > 0) {
        return `${Math.round(amountInPounds * 100)}p`;
      }

      return `£0`;
    };
  },
};
