var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ws-modal",
    _vm._g(
      {
        staticClass: "ws-product-modal",
        scopedSlots: _vm._u([
          {
            key: "hero",
            fn: function () {
              return [
                _c("ws-product-image", {
                  attrs: { height: 400, product: _vm.product },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    ),
    [
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.product.name)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "price" }, [
            _vm._v(_vm._s(_vm.menuProductPriceStr)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "description" }, [
            _vm._v(_vm._s(_vm.product.web_description)),
          ]),
          _vm._v(" "),
          _vm.isChoosingOptions === false
            ? _c(
                "div",
                { staticClass: "menu-product-lines" },
                _vm._l(
                  _vm.menuProduct.menu_product_lines,
                  function (menuProductLine) {
                    return _c(
                      "div",
                      {
                        key: menuProductLine.id,
                        staticClass: "menu-product-line",
                      },
                      [
                        _c("ws-quantity-buttons", {
                          attrs: {
                            value: _vm.localBag.getQuantity(menuProductLine),
                          },
                          on: {
                            decrement: function ($event) {
                              return _vm.localBag.removeMenuProductLine(
                                menuProductLine
                              )
                            },
                            increment: function ($event) {
                              return _vm.handleIncrementMenuProductLine(
                                menuProductLine
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                menuProductLine.product_line.product_measure
                                  .web_label
                              ) +
                              "\n        "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.hasMultipleProductLines
                          ? _c("div", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$formatCurrency(menuProductLine.price)
                                  ) +
                                  "\n        "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasOptions
                          ? _c("div", [_vm._v("Add to cart to choose options")])
                          : _vm._e(),
                      ],
                      1
                    )
                  }
                ),
                0
              )
            : _c(
                "div",
                { staticClass: "opacity-20" },
                _vm._l(
                  _vm.menuProduct.menu_product_lines,
                  function (menuProductLine) {
                    return _c(
                      "div",
                      {
                        key: menuProductLine.id,
                        staticClass: "menu-product-line",
                      },
                      [
                        _c("ws-quantity-buttons", {
                          attrs: {
                            value: _vm.localBag.getQuantity(menuProductLine),
                            disabled: "",
                          },
                        }),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                menuProductLine.product_line.product_measure
                                  .web_label
                              ) +
                              "\n        "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.hasMultipleProductLines
                          ? _c("div", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$formatCurrency(menuProductLine.price)
                                  ) +
                                  "\n        "
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    )
                  }
                ),
                0
              ),
          _vm._v(" "),
          _c("transition", { attrs: { name: "fade" } }, [
            _c(
              "div",
              {
                staticClass: "product-options",
                class:
                  ((_obj = {}),
                  (_obj["opacity-20"] = _vm.isChoosingOptions === false),
                  _obj),
              },
              _vm._l(_vm.product.product_options, function (productOption) {
                return _c(
                  "div",
                  { key: productOption.id, staticClass: "product-option" },
                  [
                    _c("div", [
                      _c("h2", [
                        _vm._v("\n              " + _vm._s(productOption.name)),
                        _c(
                          "span",
                          { staticClass: "product-option-requirement" },
                          [
                            _vm._v(
                              "  " +
                                _vm._s(
                                  _vm.productOptionRequirementStr(productOption)
                                )
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "product-option-choices" },
                      [
                        _vm._l(
                          productOption.product_lines,
                          function (productLine) {
                            return _c(
                              "div",
                              {
                                key: productLine.id,
                                staticClass: "product-option-choice",
                              },
                              [
                                _c(
                                  "ws-button",
                                  {
                                    attrs: {
                                      selected:
                                        _vm.productOptionProductLineIsChosen(
                                          productOption,
                                          productLine
                                        ),
                                      disabled:
                                        _vm.isChoosingOptions === false ||
                                        (_vm.productOptionProductLineIsChosen(
                                          productOption,
                                          productLine
                                        ) === false &&
                                          _vm.hasChosenMaximumQuantityForProductOption(
                                            productOption
                                          ) &&
                                          productOption.maximum_choices > 1),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleChooseOption(
                                          productOption,
                                          productLine
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(productLine.product.name) + " "
                                    ),
                                    productLine.price > 0
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$formatCurrency(
                                                productLine.price
                                              )
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        _vm._v(" "),
                        productOption.is_declinable
                          ? _c(
                              "div",
                              [
                                _c(
                                  "ws-button",
                                  {
                                    attrs: {
                                      selected:
                                        _vm.optionIsDeclined(productOption),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.declineOption(productOption)
                                      },
                                    },
                                  },
                                  [_vm._v("No " + _vm._s(productOption.name))]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "actions" },
            [
              _vm.isChoosingOptions
                ? _c(
                    "ws-button",
                    {
                      staticClass: "add-to-order-button",
                      attrs: {
                        variant: "cta",
                        disabled: _vm.canConfirmChoices === false,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.finishChoosingOptions()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        Confirm " +
                          _vm._s(
                            _vm.$pluralize(
                              "choice",
                              _vm.product.product_options.length
                            )
                          ) +
                          "\n        " +
                          _vm._s(
                            _vm.$formatCurrency(_vm.selectedOptionTotalPrice)
                          ) +
                          "\n      "
                      ),
                    ]
                  )
                : _c(
                    "ws-button",
                    {
                      staticClass: "add-to-order-button",
                      attrs: {
                        variant: "cta-inverted",
                        disabled:
                          _vm.wasInBag === false && _vm.isInBag === false,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.updateAndClose()
                        },
                      },
                    },
                    [
                      _vm.wasInBag
                        ? [
                            _vm.localTotalQuantity === 0
                              ? [_vm._v("Remove from order")]
                              : [
                                  _vm._v(
                                    "Update order " +
                                      _vm._s(
                                        _vm.$formatCurrency(_vm.localTotal)
                                      )
                                  ),
                                ],
                          ]
                        : [
                            _vm.localTotalQuantity === 0
                              ? [_vm._v("Choose quantity")]
                              : [
                                  _vm._v(
                                    "Add to order " +
                                      _vm._s(
                                        _vm.$formatCurrency(_vm.localTotal)
                                      )
                                  ),
                                ],
                          ],
                    ],
                    2
                  ),
              _vm._v(" "),
              _c(
                "ws-button",
                {
                  staticClass: "close-button",
                  attrs: { borderless: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_c("font-awesome-icon", { attrs: { icon: "circle-xmark" } })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }