var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ws-modal",
      on: {
        click: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "modal",
          on: {
            click: function (event) {
              return event.stopPropagation()
            },
          },
        },
        [
          _vm.$slots.hero
            ? _c("div", { staticClass: "hero" }, [_vm._t("hero")], 2)
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "content" }, [_vm._t("default")], 2),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }