var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "check-in" },
    [
      _c(
        "div",
        { staticClass: "page-title" },
        [
          _vm.isStaff
            ? [
                _c("span", { staticStyle: { color: "red" } }, [
                  _vm._v("STAFF ONLY"),
                ]),
                _c("br"),
              ]
            : _vm._e(),
          _vm._v("\n    Green Dragon"),
          _c("br"),
          _vm._v("\n    Check In\n  "),
        ],
        2
      ),
      _vm._v(" "),
      _vm.preSelectedCustomerTableId !== null
        ? _c("div", { staticClass: "page-title" }, [
            _vm._v(
              "\n    " +
                _vm._s(_vm.preSelectedCustomerTable.name) +
                " table\n  "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-form",
        {
          on: {
            submit: function ($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.submitCheckInForm.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "b-container",
            [
              _vm.preSelectedCustomerTableId === null
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "sub-header",
                                  attrs: { for: "customer_table_id" },
                                },
                                [
                                  _vm._v(
                                    "\n              Select your table\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-select",
                                {
                                  attrs: {
                                    id: "customer_table_id",
                                    required: "",
                                  },
                                  model: {
                                    value: _vm.selectedCustomerTableId,
                                    callback: function ($$v) {
                                      _vm.selectedCustomerTableId = $$v
                                    },
                                    expression: "selectedCustomerTableId",
                                  },
                                },
                                [
                                  _c("option", {
                                    domProps: {
                                      value: null,
                                      innerHTML: _vm._s("Select table…"),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.customerTableOptions,
                                    function (ct) {
                                      return _c("option", {
                                        key: ct.id,
                                        domProps: {
                                          value: ct.value,
                                          innerHTML: _vm._s(ct.text),
                                        },
                                      })
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("div", { staticClass: "track-and-trace-advice" }, [
                      _vm._v(
                        "\n            Track & Trace requires us to collect some contact\n            details… 🙄\n          "
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sub-header",
                              attrs: { for: "customer_name" },
                            },
                            [
                              _vm._v(
                                "\n              Please enter your name\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("b-input", {
                            attrs: {
                              id: "customer_name",
                              type: "text",
                              autocomplete: _vm.isStaff ? null : "name",
                              required: "",
                            },
                            model: {
                              value: _vm.customerName,
                              callback: function ($$v) {
                                _vm.customerName = $$v
                              },
                              expression: "customerName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-form-group",
                        [
                          _c(
                            "label",
                            {
                              staticClass: "sub-header",
                              attrs: { for: "customer_phone_number" },
                            },
                            [
                              _vm._v(
                                "\n              Please enter your phone number\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("b-input", {
                            attrs: {
                              type: "tel",
                              id: "customer_phone_number",
                              autocomplete: _vm.isStaff ? null : "tel",
                              required: "",
                            },
                            model: {
                              value: _vm.customerPhoneNumber,
                              callback: function ($$v) {
                                _vm.customerPhoneNumber = $$v
                              },
                              expression: "customerPhoneNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn-block",
                          attrs: {
                            type: "submit",
                            variant: "primary",
                            disabled: _vm.checkingIn,
                          },
                        },
                        [
                          _vm._v("\n            Check in\n            "),
                          _vm.showSpinner === true
                            ? _c("b-spinner", {
                                staticClass: "ml-2",
                                attrs: { small: "", label: "Spinning" },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }