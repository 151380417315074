var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ws-modal",
    {
      staticClass: "fulfilment-slots-modal",
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c("h1", [_vm._v("Choose your collection slot")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "fulfilment-slots" },
        _vm._l(_vm.sortedFulfilmentSlots, function (fulfilmentSlot) {
          return _c(
            "ws-button",
            {
              key: fulfilmentSlot.id,
              attrs: {
                block: "",
                variant: "secondary",
                disabled: fulfilmentSlot.is_full,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("selected-fulfilment-slot", fulfilmentSlot)
                },
              },
            },
            [
              _vm._v(_vm._s(_vm.$formatFulfilmentSlotTime(fulfilmentSlot))),
              _c("span", {
                domProps: { innerHTML: _vm._s(_vm.isFullText(fulfilmentSlot)) },
              }),
            ]
          )
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }