var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "simple-page" }, [
    _c(
      "h1",
      [
        _vm._t("title", function () {
          return [_vm._v(_vm._s(_vm.title))]
        }),
      ],
      2
    ),
    _vm._v(" "),
    _c("h3", [_vm._t("default")], 2),
    _vm._v(" "),
    _vm.icon
      ? _c(
          "div",
          [
            _c("font-awesome-icon", {
              style: { color: _vm.iconColor },
              attrs: { icon: _vm.icon, size: "3x" },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }