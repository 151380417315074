var render = function () {
  var _obj, _obj$1
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "gdbeer-product-card",
      style: ((_obj = {}), (_obj["--width"] = _vm.width + "px"), _obj),
    },
    [
      _c("transition", { attrs: { name: "flipped", mode: "out-in" } }, [
        _vm.flipped === false
          ? _c(
              "div",
              {
                key: "flipped",
                staticClass: "product-card-front",
                class:
                  ((_obj$1 = {}),
                  (_obj$1["cursor-pointer"] = _vm.info !== null),
                  _obj$1),
                on: {
                  click: function ($event) {
                    _vm.info !== null ? (_vm.flipped = !_vm.flipped) : null
                  },
                },
              },
              [
                _vm.imageUrl
                  ? _c("div", { staticClass: "product-card-image" }, [
                      _c("img", {
                        attrs: { src: _vm.imageUrl, srcset: _vm.imageSrcset },
                      }),
                    ])
                  : _c("div", { staticClass: "product-card-image" }, [
                      _c("img", { attrs: { src: _vm.noImg } }),
                    ]),
                _vm._v(" "),
                _c("div", { staticClass: "product-card-title-first-line" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.title.split(" ")[0]) + "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "product-card-title-rest-lines" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.title.split(" ").slice(1).join(" ")) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "price" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$formatCurrency(_vm.price)) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "product-measure-label" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.productMeasureWebLabel) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _vm.abv !== null
                  ? _c("div", { staticClass: "abv" }, [
                      _vm._v(_vm._s(_vm.$round(_vm.abv * 100)) + "%"),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "product-card-quantity" }, [
                  _vm._v("\n        " + _vm._s(_vm.quantity) + "\n      "),
                ]),
                _vm._v(" "),
                _vm.info
                  ? _c("div", { staticClass: "click-for-info" }, [
                      _vm._v("click for more info"),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", {
                  staticClass: "product-card-minus",
                  on: { click: _vm.decrementquantity },
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "product-card-plus",
                  on: { click: _vm.incrementquantity },
                }),
              ]
            )
          : _c(
              "div",
              {
                staticClass: "cursor-pointer product-card-back",
                on: {
                  click: function ($event) {
                    _vm.flipped = !_vm.flipped
                  },
                },
              },
              [
                _c("div", { staticClass: "product-card-info" }, [
                  _vm._v("\n        " + _vm._s(_vm.info) + "\n      "),
                ]),
              ]
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }