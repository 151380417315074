<template>
  <div class="age-check" v-if="hidden === false">
    <div class="age-check-title">
      For legal porpoises we have to check your age.
    </div>
    <div class="age-check-subtitle">
      Please enter your date of birth.
    </div>
    <div class="age-check-dob">
      <select v-model="year">
        <option :value="null" selected>Year</option>
        <option v-for="year in years" :value="year">{{ year }}</option>
      </select>
      <select v-model="month" :disabled="year === null">
        <option :value="null" selected>Month</option>
        <option v-for="month in months" :value="month.value">{{
          month.text
        }}</option>
      </select>
      <select v-model="day" :disabled="year === null || month === null">
        <option :value="null" selected>Day</option>
        <option v-for="day in days" :value="day">{{ day }}</option>
      </select>
    </div>
    <div class="age-check-submit">
      <button @click="submitAgeCheckForm" :disabled="this.dob === null">
        Submit
      </button>
    </div>
    <div class="age-check-subtitle">
      We do not store this information.
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { getDaysInMonth, differenceInYears } from "date-fns";
import { enGB } from "date-fns/locale";

export default {
  data: function () {
    return {
      hidden: false,
      year: null,
      month: null,
      day: null,
    };
  },
  computed: {
    years: function () {
      return _.range(1900, 2020).reverse();
    },
    months: function () {
      return _.range(0, 12).map((n) => ({
        text: enGB.localize.month(n),
        value: n,
      }));
    },
    days: function () {
      return _.range(1, getDaysInMonth(new Date(this.year, this.month)) + 1);
    },
    dob: function () {
      if (this.year === null || this.month === null || this.day === null) {
        return null;
      }

      return new Date(this.year, this.month, this.day);
    },
    age: function () {
      return differenceInYears(new Date(), this.dob);
    },
    isOldEnough: function () {
      return this.age >= 18;
    },
  },
  methods: {
    submitAgeCheckForm: function () {
      if (this.isOldEnough === false) {
        location = "https://www.google.com";
        return;
      }

      this.hidden = true;
      axios.post("is-old-enough");
    },
  },
};
</script>

<style lang="scss" scoped>
.age-check-title,
.age-check-subtitle {
  font-size: 30px;
}

.age-check-subtitle {
  margin-bottom: 10px;
}

.age-check {
  width: 100%;
  min-width: 100%;
  height: 100%;
  position: fixed;
  background: #222;
  color: #eee;
  top: 0;
  left: 0;
  overflow: hidden;
  margin: 0 auto;
  text-align: center;
}

.age-check-dob select {
  width: 100px;
  height: 30px;
  font-size: 20px;
}

.age-check button {
  width: 300px;
  height: 30px;
  font-size: 20px;
  border-radius: 5px;
  margin-top: 10px;
  background-color: #0d5ac3;
  color: white;
  cursor: pointer;
}

.age-check button:disabled {
  width: 300px;
  height: 30px;
  font-size: 20px;
  border-radius: 5px;
  margin-top: 10px;
  background-color: #ccc;
  color: white;
  cursor: default;
}
</style>
