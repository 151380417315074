import Vue from "vue";
import { BootstrapVue } from "bootstrap-vue";
import "@fontsource/roboto";

import "./gdbeer.scss";
import LodashPlugin from "@/plugins/lodash";
import CurrencyPlugin from "@/plugins/currency";
import ApiPlugin from "@/plugins/api";
import FontAwesomePlugin from "@/plugins/font-awesome";
import RoundingPlugin from "@/plugins/rounding";
import TimeFormattingPlugin from "@/plugins/time-formatting";

Vue.use(LodashPlugin);
Vue.use(BootstrapVue);
Vue.use(CurrencyPlugin);
Vue.use(ApiPlugin);
Vue.use(FontAwesomePlugin);
Vue.use(RoundingPlugin);
Vue.use(TimeFormattingPlugin);

const themeComponents = require.context("./components", true, /\.vue$/i);
themeComponents
  .keys()
  .map((key) =>
    Vue.component(
      key.split("/").pop().split(".")[0],
      themeComponents(key).default
    )
  );

const genericComponents = require.context(
  "../generic/components",
  true,
  /\.vue$/i
);
genericComponents
  .keys()
  .map((key) =>
    Vue.component(
      key.split("/").pop().split(".")[0],
      genericComponents(key).default
    )
  );

// From https://github.com/vuejs/vue/blob/dev/src/core/util/env.js
// Workaround for https://github.com/vuejs/vue/issues/11165
const inBrowser = typeof window !== "undefined";
const UA = inBrowser && window.navigator.userAgent.toLowerCase();
const isEdge = UA && UA.indexOf("edge/") > 0;
const isChrome = UA && /chrome\/\d+/.test(UA) && !isEdge;

if (isChrome) {
  window.addEventListener("pageshow", () => {
    new Vue({
      el: "#app",
    });
  });
} else {
  new Vue({
    el: "#app",
  });
}
