<template>
  <div
    class="ws-search-button-input"
    :class="{ 'search-open': isShowingSearch }"
  >
    <ws-button
      class="button"
      @click="isShowingSearch = true"
      :class="{ 'd-none': isShowingSearch === true }"
      ref="button"
    >
      <font-awesome-icon icon="magnifying-glass" />
    </ws-button>

    <ws-input
      v-if="isShowingSearch"
      class="input"
      ref="input"
      :class="{ 'd-none': isShowingSearch === false }"
      :value="value"
      @input="(newValue) => $emit('input', newValue)"
    />

    <ws-button
      class="close-button"
      v-if="isShowingSearch"
      @click="isShowingSearch = false"
      borderless
    >
      <font-awesome-icon icon="circle-xmark" />
    </ws-button>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isShowingSearch: false,
    };
  },
  computed: {},
  watch: {
    value(newValue) {
      // TODO: Clearing the search text is really a workaround for the fact
      // that due to z-index, it displays on top of the modal.
      this.searchText = "";
      if (newValue === "") {
        this.isShowingSearch = false;
      }
    },
    isShowingSearch() {
      if (this.isShowingSearch === true) {
        this.$nextTick(() => this.$refs.input.$el.focus());
      } else {
        this.$emit("input", "");
      }
    },
  },
  async created() {},
};
</script>

<style lang="scss" scoped>
.ws-search-button-input {
  position: relative;
  height: 2.5rem;

  // Place above the menu items.
  z-index: 1;

  &.search-open {
    position: absolute;
    width: 100%;
  }

  .button {
    width: 2.5rem;
  }

  .input {
    position: absolute;
    width: 100%;
  }

  .button,
  .input {
    height: 100%;
  }

  .d-none {
    display: none;
  }

  .close-button {
    position: absolute;
    height: 100%;
    right: 0;
    font-size: 1.2em;
  }
}
</style>
