var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ws-simple-page",
    { attrs: { title: "Sorry, something went wrong.", icon: "hammer" } },
    [_vm._v("\n  Don't worry, we're already looking into it.\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }