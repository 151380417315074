var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._g(
      _vm._b({ staticClass: "ws-product-card" }, "div", _vm.$attrs, false),
      _vm.$listeners
    ),
    [
      _c("div", { staticClass: "product-details" }, [
        _c("div", { staticClass: "name-and-description" }, [
          _c("div", { staticClass: "name" }, [
            _vm._v(_vm._s(_vm.product.name)),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "description" }, [
            _vm._v(_vm._s(_vm.product.web_description)),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "price" },
          [
            _vm._v("\n      " + _vm._s(_vm.priceStr) + "\n      "),
            _vm.isInBag
              ? _c("font-awesome-icon", {
                  style: { color: "#ccc", marginLeft: "0.5rem" },
                  attrs: { icon: "cart-shopping" },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("ws-product-image", {
        attrs: { product: _vm.product, width: 100, height: 100 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }