<template>
  <div class="quantity-buttons">
    <ws-button
      variant="quantity-adjustment"
      :disabled="disabled || value === 0"
      @click="
        $emit('input', value - 1);
        $emit('decrement');
      "
      >-</ws-button
    >
    <ws-button borderless>{{ value }}</ws-button>
    <ws-button
      variant="quantity-adjustment"
      :disabled="disabled"
      @click="
        $emit('input', value + 1);
        $emit('increment');
      "
      >+</ws-button
    >
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  async created() {},
};
</script>

<style lang="scss" scoped>
.quantity-buttons {
  white-space: nowrap;
  .quantity-adjustment {
    font-size: 3rem;
  }
}
</style>
