export default {
  install: function (Vue) {
    // Round a number to a precision, default 2 decimal places.
    Vue.prototype.$round = (num, precision = 2) => {
      return (
        Math.round(num * Math.pow(10, precision)) / Math.pow(10, precision)
      );
    };
  },
};
