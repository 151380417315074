var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ws-search-button-input",
      class: { "search-open": _vm.isShowingSearch },
    },
    [
      _c(
        "ws-button",
        {
          ref: "button",
          staticClass: "button",
          class: { "d-none": _vm.isShowingSearch === true },
          on: {
            click: function ($event) {
              _vm.isShowingSearch = true
            },
          },
        },
        [_c("font-awesome-icon", { attrs: { icon: "magnifying-glass" } })],
        1
      ),
      _vm._v(" "),
      _vm.isShowingSearch
        ? _c("ws-input", {
            ref: "input",
            staticClass: "input",
            class: { "d-none": _vm.isShowingSearch === false },
            attrs: { value: _vm.value },
            on: {
              input: function (newValue) {
                return _vm.$emit("input", newValue)
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isShowingSearch
        ? _c(
            "ws-button",
            {
              staticClass: "close-button",
              attrs: { borderless: "" },
              on: {
                click: function ($event) {
                  _vm.isShowingSearch = false
                },
              },
            },
            [_c("font-awesome-icon", { attrs: { icon: "circle-xmark" } })],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }