<template>
  <ws-modal class="fulfilment-slots-modal" @close="$emit('close')">
    <h1>Choose your collection slot</h1>
    <div class="fulfilment-slots">
      <ws-button
        v-for="fulfilmentSlot in sortedFulfilmentSlots"
        :key="fulfilmentSlot.id"
        block
        variant="secondary"
        @click="$emit('selected-fulfilment-slot', fulfilmentSlot)"
        :disabled="fulfilmentSlot.is_full"
        >{{ $formatFulfilmentSlotTime(fulfilmentSlot)
        }}<span v-html="isFullText(fulfilmentSlot)"
      /></ws-button>
    </div>
  </ws-modal>
</template>

<script>
import { sortBy } from "lodash";

export default {
  props: {
    fulfilmentSlots: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    sortedFulfilmentSlots() {
      return sortBy(this.fulfilmentSlots, "starts_at");
    },
  },
  methods: {
    isFullText(fulfilmentSlot) {
      if (fulfilmentSlot.is_full) {
        return " &horbar; no availability";
      }
      return "";
    },
  },
  watch: {},
  async created() {},
};
</script>

<style lang="scss" scoped>
.fulfilment-slots-modal {
  h1 {
    margin-bottom: 1rem;
  }

  .fulfilment-slots {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
</style>
