// TODO: It might be more readable if we just passed the menuProductLine Ids to
// these functions.
import { v4 as uuidv4 } from "uuid";
import { filter, sumBy, find } from "lodash";

function addMenuProductLine(bag, menuProductLine, options = []) {
  // For simplicity, for now, only match on menu_product_line_id.
  // TODO: Modify quantity for matching options.
  if (options.length) {
    bag.push({
      lid: uuidv4(),
      quantity: 1,
      menu_product_line_id: menuProductLine.id,
      options,
    });
  } else {
    const bagItem = find(bag, { menu_product_line_id: menuProductLine.id });

    if (bagItem) {
      bagItem.quantity += 1;
    } else {
      bag.push({
        lid: uuidv4(),
        quantity: 1,
        menu_product_line_id: menuProductLine.id,
        options: [],
      });
    }
  }
}

function removeMenuProductLine(bag, menuProductLine) {
  const bagItemIdx = bag.findIndex(
    (bagItem) => bagItem.menu_product_line_id === menuProductLine.id
  );
  const bagItem = bag[bagItemIdx];

  if (bagItem) {
    bagItem.quantity -= 1;

    if (bagItem.quantity === 0) {
      bag.splice(bagItemIdx, 1);
    }
  }
}

function hasMenuProductLine(bag, menuProductLine) {
  return bag.some(
    (bagItem) => bagItem.menu_product_line_id === menuProductLine.id
  );
}

function hasSomeMenuProductLines(bag, menuProductLines) {
  return menuProductLines.some((menuProductLine) =>
    hasMenuProductLine(bag, menuProductLine)
  );
}

function getQuantity(bag, menuProductLine) {
  const bagItems = filter(bag, { menu_product_line_id: menuProductLine.id });
  return sumBy(bagItems, "quantity");
}

function getOptionQuantity(bag, menuProductLine, productOption, productLine) {
  const bagItem =
    bag.find(
      (bagItem) => bagItem.menu_product_line_id === menuProductLine.id
    ) &&
    bagItem.options.some(
      (option) =>
        option.product_option_id === productOption.id &&
        option.product_line_id === productLine.id
    );

  return bagItem?.quantity || 0;
}

function isEmpty(bag) {
  return bag.length === 0;
}

function Bag(bagItems) {
  return {
    addMenuProductLine: addMenuProductLine.bind(this, bagItems),
    removeMenuProductLine: removeMenuProductLine.bind(this, bagItems),
    getQuantity: getQuantity.bind(this, bagItems),
    hasMenuProductLine: hasMenuProductLine.bind(this, bagItems),
    hasSomeMenuProductLines: hasSomeMenuProductLines.bind(this, bagItems),
    getOptionQuantity: getOptionQuantity.bind(this, bagItems),
    isEmpty: isEmpty.bind(this, bagItems),
  };
}

export { Bag };
