<template>
  <div class="ws-modal" @click="$emit('close')">
    <div class="modal" @click="(event) => event.stopPropagation()">
      <div class="hero" v-if="$slots.hero">
        <slot name="hero"></slot>
      </div>

      <div class="content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    handleKeyDown(event) {
      if (event.key === "Escape") {
        this.$emit("close");
      }
    },
  },
  async created() {
    window.addEventListener("keydown", this.handleKeyDown);
  },
  async destroed() {
    window.removeEventListener("keydown", this.handleKeyDown);
  },
};
</script>

<style lang="scss" scoped>
.ws-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);

  display: flex;
  justify-content: center;
  align-items: center;

  .modal {
    background-color: white;
    border-radius: 10px;
    width: 60%;
    max-width: 768px;
    max-height: calc(100% - 12rem);
    margin: auto;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
    overflow: scroll;

    @media (max-width: 768px) {
      width: 100%;
      height: 100%;
      max-width: inherit;
      max-height: inherit;
      border-radius: 0;
    }

    .hero {
      width: 100%;
      height: 100%;
      min-height: 400px;
      max-height: 400px;
      overflow: hidden;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
      }

      svg {
        width: 50%;
        height: 50%;
        color: red;
      }
    }

    .content {
      padding: 1.5em;
    }
  }
}
</style>
