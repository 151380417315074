import {
  chain,
  cloneDeep,
  compact,
  debounce,
  difference,
  filter,
  find,
  first,
  flatMap,
  get,
  groupBy,
  isArray,
  isFunction,
  keyBy,
  last,
  map,
  mapValues,
  maxBy,
  minBy,
  omit,
  orderBy,
  pick,
  pickBy,
  reject,
  sample,
  some,
  sortBy,
  startCase,
  sumBy,
  uniq,
  uniqBy,
  upperFirst,
  without,
} from "lodash";

export default {
  install(Vue) {
    Vue.prototype.$chain = chain;
    Vue.prototype.$cloneDeep = cloneDeep;
    Vue.prototype.$compact = compact;
    Vue.prototype.$debounce = debounce;
    Vue.prototype.$difference = difference;
    Vue.prototype.$filter = filter;
    Vue.prototype.$find = find;
    Vue.prototype.$first = first;
    Vue.prototype.$flatMap = flatMap;
    Vue.prototype.$get = get;
    Vue.prototype.$groupBy = groupBy;
    Vue.prototype.$isArray = isArray;
    Vue.prototype.$isFunction = isFunction;
    Vue.prototype.$keyBy = keyBy;
    Vue.prototype.$last = last;
    Vue.prototype.$map = map;
    Vue.prototype.$map = map;
    Vue.prototype.$mapValues = mapValues;
    Vue.prototype.$maxBy = maxBy;
    Vue.prototype.$minBy = minBy;
    Vue.prototype.$omit = omit;
    Vue.prototype.$orderBy = orderBy;
    Vue.prototype.$pick = pick;
    Vue.prototype.$pickBy = pickBy;
    Vue.prototype.$reject = reject;
    Vue.prototype.$sample = sample;
    Vue.prototype.$some = some;
    Vue.prototype.$sortBy = sortBy;
    Vue.prototype.$startCase = startCase;
    Vue.prototype.$sumBy = sumBy;
    Vue.prototype.$uniq = uniq;
    Vue.prototype.$uniqBy = uniqBy;
    Vue.prototype.$upperFirst = upperFirst;
    Vue.prototype.$without = without;
  },
};
