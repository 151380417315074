<template>
  <input
    v-bind="$attrs"
    :value="value"
    @input="(event) => $emit('input', event.target.value)"
    class="ws-input"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  computed: {},
  watch: {},
  async created() {},
};
</script>

<style lang="scss" scoped>
.ws-input {
  border: 1px solid var(--dark-grey);
  border-radius: 5px;
  padding: 0.5rem 0.75rem;
  height: 2em;
}
</style>
