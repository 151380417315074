import { isToday, isTomorrow } from "date-fns";

export default {
  install(Vue) {
    Vue.prototype.$formatFulfilmentSlotTime = (fulfilmentSlot) => {
      const startsAt = new Date(fulfilmentSlot.starts_at);
      const pieces = [];
      if (isToday(startsAt)) {
        pieces.push("Today");
      } else if (isTomorrow(startsAt)) {
        pieces.push("Tomorrow");
      } else {
        let startsAtTime = startsAt.toLocaleDateString("en-GB", {
          weekday: "long",
          month: "long",
          day: "numeric",
        });

        if (startsAtTime === "12:00 PM") {
          startsAtTime = "Midday";
        }

        pieces.push(startsAtTime);
      }

      pieces.push("at");

      // Personal preference, but I prefer US-style time with AM / PM.
      pieces.push(
        startsAt.toLocaleTimeString("en-US", {
          hour: "numeric",
          minute: "numeric",
        })
      );

      return pieces.join(" ");
    };
  },
};
