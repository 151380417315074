<!-- TODO:
  - [ ] Remove fulfilment slot if it is no longer valid.
  - [ ] Support collection and delivery.
-->
<template>
  <div class="cart">
    <div class="content">
      <div class="header">
        <h1>{{ title }}</h1>
      </div>

      <div class="continue-ordering">
        <ws-button href="/" borderless>
          <font-awesome-icon icon="caret-left" /> Continue Ordering
        </ws-button>
      </div>

      <ws-fulfilment-slots-modal
        v-if="isSelectingFulfilmentSlot"
        :fulfilment-slots="fulfilmentSlots"
        @selected-fulfilment-slot="setSelectedFulfilmentSlot"
        @close="isSelectingFulfilmentSlot = false"
      />

      <div class="body">
        <div class="cart-section">
          <div class="cart-title"><h2>YOUR CART</h2></div>

          <div class="cart-errors" v-if="errors.length > 0">
            <div v-for="error in errors" :key="error">{{ error }}</div>
          </div>

          <div class="cart-errors" v-if="cartErrors.length > 0">
            <div v-for="error in cartErrors" :key="error">{{ error }}</div>
          </div>

          <div class="cart-subtitle">
            <template v-if="localBag.isEmpty()">Your cart is empty</template>
            <template v-else
              >YOUR ORDER ({{
                $pluralize("ITEM", menuProductLinesInBag.length, true)
              }})</template
            >
          </div>

          <div class="cart-items">
            <div
              v-for="menuProductLineInBag in menuProductLinesInBag"
              :key="menuProductLineInBag.id"
              class="cart-item"
            >
              <ws-product-image
                class="product-image"
                :product="menuProductLineInBag.product"
                :width="80"
                :height="80"
              />

              <div class="cart-description">
                <div class="cart-item-name">
                  {{ menuProductLineInBag.product.name }}
                </div>

                <div class="cart-item-product-measure">
                  {{
                    menuProductLineInBag.menu_product_line.product_line
                      .product_measure.web_label
                  }}
                </div>

                <div
                  class="cart-item-option"
                  v-for="option in $reject(menuProductLineInBag.options, {
                    is_declined: true,
                  })"
                  :key="option.product_line_id"
                >
                  <span>
                    With
                    {{ productLinesById[option.product_line_id].product.name }}
                  </span>

                  <span
                    v-if="productLinesById[option.product_line_id].price > 0"
                    >{{
                      $formatCurrency(
                        productLinesById[option.product_line_id].price
                      )
                    }}</span
                  >
                </div>

                <div class="cart-item-quantity">
                  <ws-quantity-buttons
                    v-model="menuProductLineInBag.quantity"
                    @input="saveBag"
                  />
                </div>
              </div>
              <div class="cart-actions">
                <div>
                  {{ $formatCurrency(getBagItemPrice(menuProductLineInBag)) }}
                </div>
                <div>
                  <ws-button
                    @click="removeFromBag(menuProductLineInBag)"
                    borderless
                  >
                    <font-awesome-icon icon="trash" size="lg" />
                  </ws-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="fulfilment-section">
          <div>
            <div class="fulfilment-method"><h3>COLLECTION</h3></div>
            <div class="fulfilment-time">
              <ws-button @click="isSelectingFulfilmentSlot = true">
                <font-awesome-icon icon="clock" />
                &nbsp;
                <template v-if="selectedFulfilmentSlot">
                  {{ $formatFulfilmentSlotTime(selectedFulfilmentSlot) }}
                </template>
                <template v-else>Choose a collection time</template>
              </ws-button>
            </div>
          </div>

          <hr />
          <div class="totals">
            <div>Total</div>
            <div>{{ $formatCurrency(total) }}</div>
          </div>

          <div class="checkout">
            <!-- TODO: I think a better UX would be showing the fulfilment slot modal
              if no slot is seleted, rather than disabling the button. -->
            <ws-button
              href="/checkout"
              variant="cta-inverted"
              block
              size="lg"
              ref="checkoutButton"
              @click="(event) => checkout(event)"
              :disabled="total === 0"
            >
              Checkout {{ $formatCurrency(total) }}
            </ws-button>
          </div>
        </div>
      </div>
    </div>

    <footer class="footer">
      <div class="secure-checkout-text">
        Secure checkout by
        <span class="fa-icon">
          <font-awesome-icon :icon="['fab', 'stripe']" size="2x" />
        </span>
      </div>
      <div class="cc-icons">
        <font-awesome-icon :icon="['fab', 'cc-visa']" size="lg" />
        <font-awesome-icon :icon="['fab', 'cc-mastercard']" size="lg" />
        <font-awesome-icon :icon="['fab', 'cc-apple-pay']" size="lg" />
        <font-awesome-icon :icon="['fab', 'cc-amex']" size="lg" />
        <font-awesome-icon :icon="['fab', 'google-pay']" size="lg" />
      </div>
    </footer>
  </div>
</template>

<script>
import { chain } from "lodash";
import { Bag } from "@/lib/bag-utils.js";

export default {
  props: {
    title: {
      required: true,
      type: String,
    },
    canCheckout: {
      required: true,
      type: Boolean,
    },
    initialMenuProductLinesInBag: {
      required: true,
      type: Array,
    },
    menu: {
      required: true,
      type: Object,
    },
    fulfilmentSlots: {
      required: true,
      type: Array,
    },
    initialSelectedFulfilmentSlotId: {
      required: false,
      type: Number,
      default: null,
    },
    cartErrors: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      // Runs after computed properties.
      errors: [],
      menuProductLinesInBag: [],
      isSelectingFulfilmentSlot: false,
      isCheckingOut: false,
      selectedFullfilmentSlotId: null,
    };
  },
  computed: {
    selectedFulfilmentSlot() {
      if (this.selectedFullfilmentSlotId === null) {
        return null;
      }

      return this.$find(this.fulfilmentSlots, {
        id: this.selectedFullfilmentSlotId,
      });
    },
    menuProductsById() {
      return chain(this.menu.menu_display_groups)
        .flatMap("menu_products")
        .keyBy("id")
        .value();
    },
    productsById() {
      return chain(this.menu.menu_display_groups)
        .flatMap("menu_products")
        .flatMap("product")
        .keyBy("id")
        .value();
    },
    productOptionsById() {
      return chain(Object.values(this.productsById))
        .flatMap("product_options")
        .keyBy("id")
        .value();
    },
    menuProductLinesById() {
      return chain(this.menu.menu_display_groups)
        .flatMap("menu_products")
        .flatMap("menu_product_lines")
        .keyBy("id")
        .value();
    },
    productLinesById() {
      const productLinesFromMenuProducts = chain(
        Object.values(this.menuProductLinesById)
      )
        .map("product_line")
        .keyBy("id")
        .value();

      const productLinesFromProductOptions = chain(
        Object.values(this.productOptionsById)
      )
        .flatMap("product_lines")
        .keyBy("id")
        .value();

      return {
        ...productLinesFromMenuProducts,
        ...productLinesFromProductOptions,
      };
    },
    total() {
      return this.$chain(this.menuProductLinesInBag)
        .map((menuProductLineInBag) =>
          this.getBagItemPrice(menuProductLineInBag)
        )
        .sum()
        .value();
    },
    localBag() {
      return new Bag(this.menuProductLinesInBag);
    },
  },
  methods: {
    addError(errorMessage) {
      this.errors.push(errorMessage);
    },
    addUniqueError(errorMessage) {
      if (this.errors.includes(errorMessage) === false) {
        this.addError(errorMessage);
      }
    },

    hydrateMenuProductLinesInBag(menuProductLinesInBag) {
      // TODO: This is really similar to a function in lib/bag-utils.js.
      const hydratedMenuProductLinesInBag = menuProductLinesInBag
        .map((menuProductLineInBag) => {
          let product = undefined;

          const menuProductLine =
            this.menuProductLinesById[
              menuProductLineInBag.menu_product_line_id
            ];

          if (menuProductLine === undefined) {
            this.addUniqueError(
              "Some items that were in your cart are no longer available and have been removed."
            );

            console.log(
              `Menu product line ${menuProductLineInBag.menu_product_line_id} not found in menu.`
            );
          } else {
            product =
              this.productsById[menuProductLine.product_line.product_id];
          }

          return {
            ...menuProductLineInBag,
            menu_product_line: menuProductLine,
            product: product,
          };
        })
        .filter(
          (menuProductLineInBag) =>
            menuProductLineInBag.menu_product_line !== undefined &&
            menuProductLineInBag.product !== undefined
        );

      return hydratedMenuProductLinesInBag;
    },
    removeFromBag(menuProductLineInBagToRemove) {
      this.menuProductLinesInBag = this.$reject(this.menuProductLinesInBag, {
        lid: menuProductLineInBagToRemove.lid,
      });
      this.saveBag();
    },
    selectFulfilmentSlot() {
      this.$emit("select-fulfilment-slot");
    },
    async saveBag() {
      await this.$api.put("bag/menu-product-lines", this.menuProductLinesInBag);
    },
    async setSelectedFulfilmentSlot(fulfilmentSlot) {
      this.isSelectingFulfilmentSlot = false;
      this.selectedFullfilmentSlotId = fulfilmentSlot.id;
      await this.$api.put("fulfilment-slot-id", {
        fulfilmentSlotId: fulfilmentSlot.id,
      });

      if (this.isCheckingOut) {
        this.$refs.checkoutButton.$el.click();
      }
    },
    getBagItemPrice(bagItem) {
      // TODO: Factor this out.
      const getOptionsPrice = (options) =>
        this.$chain(options)
          .reject({ is_declined: true })
          .map((option) => this.productLinesById[option.product_line_id].price)
          .sum();

      return (
        bagItem.quantity *
        (this.menuProductLinesById[bagItem.menu_product_line_id].price +
          getOptionsPrice(bagItem.options))
      );
    },
    validateBag() {
      let hasChanged = false;

      // This validation should happen on the server, but this is a last ditch
      // effort to make sure the page still renders if something goes wrong with
      // the cart.
      this.menuProductLinesInBag = this.menuProductLinesInBag.filter(
        (menuProductLineInBag) => {
          const menuProductLine = menuProductLineInBag.menu_product_line;
          if (
            this.productLinesById[menuProductLine.product_line_id] === undefined
          ) {
            this.$reportError(
              new Error(
                `Product line ${menuProductLine.product_line_id} not found in menu.`
              )
            );
            hasChanged = true;
            return false;
          }

          for (const option of menuProductLineInBag.options) {
            if (this.productLinesById[option.product_line_id] === undefined) {
              this.$reportError(
                new Error(
                  `Product line option ${option.product_line_id} not found in menu.`
                )
              );
              hasChanged = true;
              return false;
            }
          }

          return true;
        }
      );

      if (hasChanged) {
        this.saveBag();
      }
    },
    checkout(event) {
      if (this.canCheckout === false) {
        // This doesn't need to look nice, it is usually temporary.
        alert("Sorry, checkout is not enabled on this shop.");
        event.preventDefault();
        return;
      }

      // Remember this so we can proceed to checkout atfer selecting a fulfilment slot.
      this.isCheckingOut = true;

      if (this.selectedFullfilmentSlotId === null) {
        this.isSelectingFulfilmentSlot = true;
        if (event) {
          event.preventDefault();
        }
      }
    },
  },
  watch: {},
  async created() {
    this.menuProductLinesInBag = this.hydrateMenuProductLinesInBag(
      this.initialMenuProductLinesInBag
    );

    if (this.initialSelectedFulfilmentSlotId !== null) {
      this.selectedFullfilmentSlotId = this.initialSelectedFulfilmentSlotId;
    }

    this.validateBag();

    // Save after filtering out invalid items.
    this.saveBag();
  },
};
</script>

<style lang="scss" scoped>
.cart {
  $footerHeight: 100px;
  $continueShoppingWidth: 10rem;

  .content {
    min-height: calc(100vh - #{$footerHeight});

    // Put this in a variable or component.
    max-width: 1024px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0.5rem;

    .header {
      text-align: center;
      max-width: calc(100% - #{2 * $continueShoppingWidth});
      align-self: center;
    }

    .continue-ordering {
      position: absolute;
      top: 1rem;
      height: 2rem;
      line-height: 2rem;

      left: 0.5rem;
      font-weight: bold;
      width: $continueShoppingWidth;
    }

    .body {
      margin-top: 1rem;
      display: grid;

      padding: 0 1rem;

      // TODO: Make this breakpoint configurable.
      @media (max-width: 640px) {
        grid-template-columns: 1fr;
        padding: 0 2rem;
      }

      @media (max-width: 480px) {
        padding: 0 1rem;
      }

      @media (max-width: 380px) {
        padding: 0 0.25rem;
      }

      grid-template-columns: 1fr 1fr;
      gap: 3rem;

      .cart-section {
        // TODO: Single column for smaller screens.

        .cart-title {
          margin-bottom: 1rem;
        }

        .cart-errors {
          background-color: #f8d7da;
          padding: 0.5rem 1rem;
          border-radius: 0.5rem;
          margin: 1rem 0;
          line-height: 1.6em;
        }

        .cart-subtitle {
          font-weight: bold;
          margin-bottom: 1rem;
        }

        .cart-items {
          display: flex;
          flex-direction: column;
          gap: 2rem;

          .cart-item {
            display: grid;
            grid-template-columns: 1fr 3fr 1fr;
            grid-gap: 1rem;

            .product-image {
              align-self: flex-start;
            }

            .cart-description {
              display: flex;
              flex-direction: column;
              gap: 0.5em;

              .cart-item-name {
                font-weight: bold;
              }
            }

            .cart-item-option {
              font-size: 0.8em;
            }

            .cart-actions {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: flex-end;
              font-weight: bold;
            }
          }
        }
      }
    }

    .fulfilment-section {
      display: flex;
      flex-direction: column;
      gap: 1em;

      .fulfilment-method {
        margin-bottom: 1rem;
      }

      .totals {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        margin-bottom: 1rem;
      }
    }
  }

  .footer {
    color: white;
    min-height: $footerHeight;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;

    .secure-checkout-text {
      display: flex;
      align-items: center;
      line-height: 1.1em; // nudge Stripe logo.

      .fa-icon {
        margin-left: 0.25rem;
      }
    }

    background: linear-gradient(
      180deg,
      rgba(50, 59, 67, 100) 0%,
      rgba(54, 64, 75, 100) 0%
    );

    .cc-icons {
      display: flex;
      gap: 0.25rem;
    }

    img.powered-by-logo {
      max-width: 80px;
    }
  }
}
</style>
