var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "image",
      style: {
        width: _vm.computedWidth,
        height: _vm.computedHeight,
      },
    },
    [
      _vm.value.image_url
        ? _c("img", {
            style: {
              width: _vm.computedWidth,
              height: _vm.computedHeight,
            },
            attrs: {
              src: _vm.value.image_url,
              srcset: _vm.value.image_srcset,
              width: _vm.width,
              height: _vm.height,
            },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }