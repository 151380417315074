var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "gdbeer-index" }, [
    _vm.placedOrder !== null
      ? _c(
          "div",
          {
            ref: "placedOrder",
            staticClass: "box gradient-border checkout-success",
          },
          [
            _c("div", { staticClass: "header" }, [_vm._v("THANK YOU!")]),
            _vm._v(" "),
            _c("div", { staticClass: "body" }, [
              _c("div", [_vm._v("We've got your order.")]),
              _vm._v(" "),
              _c("div", { staticClass: "collection-time" }, [
                _vm._v(
                  "\n        Please collect\n        " +
                    _vm._s(
                      _vm
                        .$formatFulfilmentSlotTime(
                          _vm.placedOrder.fulfilmentSlot
                        )
                        .toLowerCase()
                    ) +
                    "\n      "
                ),
              ]),
            ]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _c("div", { staticClass: "products" }, [
      _vm._m(2),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "product-cards" },
        _vm._l(_vm.menuProductLines, function (menuProductLine) {
          return _c("gdbeer-product-card", {
            key: menuProductLine.id,
            attrs: {
              width: 200,
              title: menuProductLine.product.name,
              price: menuProductLine.price,
              abv: menuProductLine.product.abv,
              "image-url": menuProductLine.product.image_url,
              info: menuProductLine.product.web_description,
              "product-measure-web-label":
                menuProductLine.product_line.product_measure.web_label,
            },
            model: {
              value: menuProductLine.quantity,
              callback: function ($$v) {
                _vm.$set(menuProductLine, "quantity", $$v)
              },
              expression: "menuProductLine.quantity",
            },
          })
        }),
        1
      ),
    ]),
    _vm._v(" "),
    _vm.menuProductLinesInBag.length === 0
      ? _c("div", { staticClass: "box" }, [
          _vm._v("\n    Your basket is empty.\n  "),
        ])
      : _c(
          "div",
          { staticClass: "basket-and-order-form d-flex gap-5 flex-column" },
          [
            _c(
              "div",
              { staticClass: "box basket" },
              [
                _vm._l(_vm.menuProductLinesInBag, function (menuProductLine) {
                  return _c(
                    "div",
                    { key: menuProductLine.id, staticClass: "basket-item" },
                    [
                      _c("div", [
                        _vm._v(
                          "\n          " +
                            _vm._s(menuProductLine.product.name) +
                            "\n          "
                        ),
                        _c("span", { staticClass: "text-muted" }, [
                          _vm._v(
                            _vm._s(
                              menuProductLine.product_line.product_measure
                                .web_label
                            )
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          "\n          " +
                            _vm._s(menuProductLine.quantity) +
                            "\n          "
                        ),
                        _c("span", { style: { fontWeight: 400 } }, [
                          _vm._v("×"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$formatCurrency(
                                menuProductLine.price * menuProductLine.quantity
                              )
                            ) +
                            "\n        "
                        ),
                      ]),
                    ]
                  )
                }),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c("div", { staticClass: "basket-item" }, [
                  _c("div", [_vm._v("Total")]),
                  _vm._v(" "),
                  _c("div"),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$formatCurrency(
                            _vm.$sumBy(
                              _vm.menuProductLinesInBag,
                              function (menuProductLine) {
                                return (
                                  menuProductLine.price *
                                  menuProductLine.quantity
                                )
                              }
                            )
                          )
                        ) +
                        "\n        "
                    ),
                  ]),
                ]),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "order-form" },
              [
                _c("h2", [_vm._v("Your details")]),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "customer-name",
                      description:
                        "Enter a name so we know who you are when you collect.",
                      label: "Enter your name",
                      "valid-for": "customer-name-input",
                      "invalid-feedback": _vm.formErrors.customer_name,
                      state: _vm.formStates.customer_name,
                    },
                  },
                  [
                    _c("b-input", {
                      attrs: {
                        id: "customer-name-input",
                        state: _vm.formStates.customer_name,
                        autocomplete: "name",
                        trim: "",
                      },
                      on: {
                        input: function ($event) {
                          _vm.formErrors.customer_name = null
                        },
                      },
                      model: {
                        value: _vm.formData.customer_name,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "customer_name", $$v)
                        },
                        expression: "formData.customer_name",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "customer-phone-number",
                      description:
                        "Enter a phone number so we can contact you when your order is ready.",
                      label: "Enter your phone number",
                      "valid-for": "customer-phone-number-input",
                      "invalid-feedback": _vm.formErrors.customer_phone_number,
                      state: _vm.formStates.customer_phone_number,
                    },
                  },
                  [
                    _c("b-input", {
                      attrs: {
                        id: "customer-phone-number-input",
                        state: _vm.formStates.customer_phone_number,
                        autocomplete: "phone",
                        trim: "",
                      },
                      on: {
                        input: function ($event) {
                          _vm.formErrors.customer_phone_number = null
                        },
                      },
                      model: {
                        value: _vm.formData.customer_phone_number,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "customer_phone_number", $$v)
                        },
                        expression: "formData.customer_phone_number",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "fulfilment-slot-id",
                      description: "Choose a collection time.",
                      label: "Choose a time to collect your order",
                      "valid-for": "fulfilment-slot-id-input",
                      "invalid-feedback": _vm.formErrors.fulfilment_slot_id,
                      state: _vm.formStates.fulfilment_slot_id,
                    },
                  },
                  [
                    _c("b-select", {
                      attrs: {
                        options: _vm.fulfilmentSlotOptions,
                        state: _vm.formStates.fulfilment_slot_id,
                      },
                      on: {
                        input: function ($event) {
                          _vm.formErrors.fulfilment_slot_id = null
                        },
                      },
                      model: {
                        value: _vm.formData.fulfilment_slot_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "fulfilment_slot_id", $$v)
                        },
                        expression: "formData.fulfilment_slot_id",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      id: "payment-method",
                      description: "Choose how you want to pay.",
                      label: "Choose payment",
                      "valid-for": "payment-method-input",
                      "invalid-feedback": _vm.formErrors.pay_online,
                      state: _vm.formStates.pay_online,
                    },
                  },
                  [
                    _c("b-form-radio-group", {
                      attrs: {
                        state: _vm.formStates.pay_online,
                        options: _vm.payOnlineOptions,
                        name: "payment-method",
                        stacked: "",
                        size: "lg",
                      },
                      on: {
                        input: function ($event) {
                          _vm.formErrors.pay_online = null
                        },
                      },
                      model: {
                        value: _vm.formData.pay_online,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "pay_online", $$v)
                        },
                        expression: "formData.pay_online",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.formErrorMessage
                  ? _c("b-alert", { attrs: { show: "", variant: "danger" } }, [
                      _vm._v(
                        "\n        " + _vm._s(_vm.formErrorMessage) + "\n      "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "b-button",
                  {
                    attrs: {
                      type: "submit",
                      variant: "primary",
                      disabled: _vm.isSubmitting,
                    },
                    on: { click: _vm.submitOrder },
                  },
                  [
                    _vm.isSubmitting
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.formData.pay_online === true
                                ? "Proceeding to payment..."
                                : "Placing order..."
                            ) + "\n        "
                          ),
                        ])
                      : _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.formData.pay_online === true
                                ? "Proceed to payment"
                                : "Place order"
                            )
                          ),
                        ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm._m(3),
          ]
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex flex-column align-items-center" }, [
      _c("h1", [_vm._v("Green Dragon")]),
      _vm._v(" "),
      _c("h2", [_vm._v("Beer Takeaway Service")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "box instructions" }, [
      _c("ol", [
        _c("li", [_vm._v("Choose your beer")]),
        _vm._v(" "),
        _c("li", [_vm._v("Provide your name & number")]),
        _vm._v(" "),
        _c("li", [_vm._v("Choose a collection time")]),
        _vm._v(" "),
        _c("li", [_vm._v("Pay now or when you collect")]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("h2", [_vm._v("Order")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v("\n      If you need help please call the pub on\n      "),
      _c("a", { attrs: { href: "tel:01986 892681" } }, [
        _vm._v("01986 892681"),
      ]),
      _vm._v(".\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }