<template>
  <div class="check-in">
    <div class="page-title">
      <template v-if="isStaff"
        ><span style="color: red;">STAFF ONLY</span><br />
      </template>
      Green Dragon<br />
      Check In
    </div>

    <div class="page-title" v-if="preSelectedCustomerTableId !== null">
      {{ preSelectedCustomerTable.name }} table
    </div>

    <b-form @submit.stop.prevent="submitCheckInForm">
      <b-container>
        <b-row v-if="preSelectedCustomerTableId === null">
          <b-col>
            <b-form-group>
              <label class="sub-header" for="customer_table_id">
                Select your table
              </label>

              <b-select
                id="customer_table_id"
                v-model="selectedCustomerTableId"
                required
              >
                <option v-html="`Select table…`" :value="null" />
                <option
                  v-for="ct in customerTableOptions"
                  v-html="ct.text"
                  :value="ct.value"
                  v-bind:key="ct.id"
                />
              </b-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="track-and-trace-advice">
              Track &amp; Trace requires us to collect some contact
              details&hellip; 🙄
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group>
              <label for="customer_name" class="sub-header">
                Please enter your name
              </label>
              <!-- Do not autocomplete if staff are logging in -->
              <b-input
                id="customer_name"
                type="text"
                v-model="customerName"
                :autocomplete="isStaff ? null : `name`"
                required
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group>
              <label for="customer_phone_number" class="sub-header">
                Please enter your phone number
              </label>
              <!-- Do not autocomplete if staff are logging in -->
              <b-input
                type="tel"
                id="customer_phone_number"
                v-model="customerPhoneNumber"
                :autocomplete="isStaff ? null : `tel`"
                required
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-button
              type="submit"
              variant="primary"
              class="btn-block"
              :disabled="checkingIn"
            >
              Check in
              <b-spinner
                small
                class="ml-2"
                v-if="showSpinner === true"
                label="Spinning"
              />
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-form>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import { isToday, parseISO } from "date-fns";

export default {
  props: {
    customerTables: {
      required: true,
      type: Array,
    },
    tn: {
      type: String,
      required: false,
      default: null,
    },
    isStaff: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: function () {
    return {
      checkingIn: false,
      showSpinner: false,
      preSelectedCustomerTableId: _.get(
        this.customerTables.find((ct) => ct.name === this.tn),
        "id",
        null
      ),
      selectedCustomerTableId: _.get(
        this.customerTables.find((ct) => ct.name === this.tn),
        "id",
        null
      ),
      customerName: this.isStaff ? "" : window.localStorage.customerName || "",
      customerPhoneNumber: this.isStaff
        ? ""
        : window.localStorage.customerPhoneNumber || "",
    };
  },
  computed: {
    preSelectedCustomerTable() {
      return this.customerTables.find(
        (ct) => ct.id === this.preSelectedCustomerTableId
      );
    },
    selectedCustomerTable() {
      return this.customerTables.find(
        (ct) => ct.id === this.selectedCustomerTableId
      );
    },
    customerTableOptions() {
      return _.sortBy(
        this.customerTables.map((ct) => ({
          text: this.tableName(ct),
          value: ct.id,
        })),
        "text"
      );
    },
  },
  watch: {},
  methods: {
    async submitCheckInForm() {
      this.checkingIn = true;
      const showSpinner = _.debounce(() => (this.showSpinner = true), 1000);
      showSpinner();

      if (this.isStaff === false) {
        window.localStorage.customerName = this.customerName;
        window.localStorage.customerPhoneNumber = this.customerPhoneNumber;
      }

      await axios
        .post("/ts/check-in", {
          customerTableId: this.selectedCustomerTableId,
          customerName: this.customerName,
          customerPhoneNumber: this.customerPhoneNumber,
        })
        .then((res) => {
          showSpinner.cancel();
          this.checkingIn = false;
          this.showSpinner = false;

          if (res.data.status === "ok") {
            location.href = res.data.redirect;
            return;
          }

          if (this.isStaff) {
            alert(res.data.message);
            return;
          }

          alert("Oops, something went wrong.");
        })
        .catch((err) => {
          showSpinner.cancel();
          this.checkingIn = false;
          alert("Oops, someting went wrong.");
        });
    },
    tableName(customerTable) {
      const pieces = [];
      if (this.isStaff) {
        pieces.push(customerTable.zone, " - ");
      }

      pieces.push(customerTable.name);

      if (
        this.isStaff &&
        customerTable.customer_check_in &&
        isToday(parseISO(customerTable.customer_check_in.created_at))
      ) {
        pieces.push(`[${customerTable.customer_check_in.customer_name}]`);
      }

      return pieces.join(" ");
    },
  },
  components: {},
  created: function () {},
  mounted: function () {
    document.body.classList.add("dark-background");
  },
};
</script>
